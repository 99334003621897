import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { withRouter } from "react-router-dom";

const StyledSiteHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.theme.navbarHeight};
  background: ${props => props.theme.colors.white};
  z-index: 20;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  .left,
  .right {
    line-height: 1;
    display: flex;
    align-items: center;
  }
  .logo {
    height: 35px;
    width: auto;
    cursor: pointer;
  }

  /* User & Dropdown */
  .user {
    position: relative;
    .toggle,
    .avatar {
      margin-right: 0.5rem;
    }
    .toggle {
      height: ${props => props.theme.navbarHeight};
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &:hover {
        cursor: pointer;
      }
    }
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-image: url(${props => props.avatar});
      background-size: cover;
      background-position: center;
    }
    .menu {
      display: none;

      &.open {
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        width: auto;
        padding: 0.5rem;
        border-radius: 0.35rem;
        background: ${props => props.theme.colors.white};
        box-shadow: ${props => props.theme.shadow};
      }
    }
  }
`;

function SiteHeader({ location }) {
  return (
    <StyledSiteHeader avatar={""}>
      <div className="left">
        <a href="/">
          <Logo className="logo" />
        </a>
      </div>
    </StyledSiteHeader>
  );
}

export default withRouter(SiteHeader);
