import React, { Component } from "react";
import {
  Button,
  Confirm,
  Dimmer,
  Divider,
  Form,
  Loader,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import { Subscribe } from "unstated";
import autobind from "react-autobind";
import DatePicker from "react-datepicker";
import papa from "papaparse";
import ContractContainer from "../containers/ContractContainer";
import { toast } from "react-toastify";
import { Edit, X } from "react-feather";
import styles from "./styles/contractContingentWorkersStyles";
import classNames from "classnames";
import CSVTemplate from "../documents/worker_data_template.xls";
import { StyledHeader, StyledOverview } from "../styles/components";

const supplierHGS = "SUP-004056";
const supplierMTB = "SUP-007160";
const supplierTIG = "SUP-011637";

const profileUtility = 2094;

class ContractContingentWorkers extends Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    const { contractContainer } = this.props;
    if (contractContainer.state.suppliers.length === 0) {
      contractContainer.fetchSuppliers();
    }
  }

  handleSupplierChange(e, data) {
    const { contractContainer } = this.props;
    const { startContract } = this.props.contractContainer.state;
    const newSupplierId = data.value;

    if (newSupplierId && newSupplierId !== startContract.supplierId) {
      if (startContract.supplierId) {
        contractContainer.showConfirmSupplier(newSupplierId);
      } else {
        contractContainer.setStartContractSupplierId(newSupplierId);
      }
    }
  }

  handleProfileChange(event, { value }) {
    const { startContract } = this.props.contractContainer.state;
    startContract.profileId = value;
    startContract.phoneSystemId = 0;

    this.props.contractContainer.setState({
      startContract,
    });
  }

  handlePhoneSystemChange(event, { value }) {
    const { startContract } = this.props.contractContainer.state;
    startContract.phoneSystemId = value;

    this.props.contractContainer.setState({
      startContract,
    });
  }

  handleLocationChange(event, { value }) {
    const { startContract } = this.props.contractContainer.state;
    startContract.locationId = value;
    this.props.contractContainer.setState({
      startContract,
    });
  }

  handleCancelConfirmSupplier() {
    this.props.contractContainer.declineConfirmSupplier();
  }

  handleConfirmSupplier() {
    const { contractContainer } = this.props;
    contractContainer.setStartContractSupplierId(
      contractContainer.state.startContract.ui.supplierId
    );
  }

  handleGroupFieldValueChange(fieldKey, value) {
    this.props.contractContainer.setFieldValue(fieldKey, value);
  }

  handleFileChange(e, data) {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    if (!file.name.endsWith(".csv")) {
      toast.error("Error loading file: Must upload file with csv file format");
      return;
    }

    const { contractContainer } = this.props;

    papa.parse(file, {
      complete: (results) => {
        if (results.errors) {
          results.errors.forEach((error) => {
            toast.error(`Error parsing file: Row ${error.row} - ${error.code} - ${error.message}`);
          });
        }

        const workers = [];
        if (results.data && results.data.length > 1) {
          // Removing header row
          const rows = results.data;
          rows.shift();
          rows.forEach((row) => {
            let worker = {
              uniqueId: row[0].trim(),
              firstName: row[1].trim(),
              lastName: row[2].trim(),
              workEmailAddress: row[3].trim(),
            };

            contractContainer.state.startContract.fields.forEach((field) => {
              if (!worker[field.key] && field.value) {
                worker[field.key] = field.value;
              }
            });

            if (worker.uniqueId || worker.firstName || worker.lastName || worker.workEmailAddress) {
              workers.push(worker);
            }
          });
        }

        if (workers) {
          contractContainer.checkUniqueIDs(workers);
          contractContainer.setStartContractWorkers(workers);
        } else {
          toast.error(`Error parsing file: Unable to read data`);
        }
      },
      error: (error) => {
        toast.error(`Error loading file: ${error.message}`);
      },
    });
  }

  handleSaveWorkerClick() {
    this.props.contractContainer.saveWorker();
  }

  handleCloseWorkerEditForm() {
    this.props.contractContainer.closeWorkerEditForm();
  }

  handleAddWorkerClick() {
    this.props.contractContainer.openWorkerAddForm();
  }

  handleSubmitClick() {
    const { contractContainer } = this.props;
    if (contractContainer.validateWorkers()) {
      contractContainer.showConfirmSubmit();
    }
  }

  handleCancelConfirm() {
    this.props.contractContainer.cancelConfirmSubmit();
  }

  handleSubmitConfirm() {
    this.props.contractContainer.submitStartContracts();
    this.fileInput.current.value = null;
  }

  getSupplierOptions() {
    const { suppliers } = this.props.contractContainer.state;
    return suppliers.map((supplier) => {
      return {
        key: supplier.id,
        value: supplier.id,
        text: supplier.name,
      };
    });
  }

  getProfileOptions() {
    return [
      {
        key: 2094,
        value: 2094,
        text: "Utility",
      },
      {
        key: 2126,
        value: 2126,
        text: "Digital",
      },
    ];
  }

  getPhoneSystemOptions(profile) {
    switch (profile) {
      case profileUtility: {
        return [
          {
            key: 20,
            value: 20,
            text: "Sales",
          },
          {
            key: 21,
            value: 21,
            text: "Customer Care",
          },
        ];
      }
      default: {
        return [
          {
            key: 20,
            value: 20,
            text: "Sales",
          },
        ];
      }
    }
  }

  getLocationOptions(supplierID) {
    switch (supplierID) {
      case supplierHGS: {
        return [
          {
            key: 1,
            value: 1,
            text: "M&A", //workdayLocationID: "LOCATION-341"
          },
          {
            key: 2,
            value: 2,
            text: "Colombia", //workdayLocationID: "LOCATION-3-139"
          },
          {
            key: 3,
            value: 3,
            text: "Jamaica - Team HGS", //workdayLocationID:  "LOC-381"
          },
          {
            key: 4,
            value: 4,
            text: "Illinois - Team HGS", //workdayLocationID:  "LOC-033"
          },
        ];
      }
      case supplierMTB: {
        return [
          {
            key: 1,
            value: 1,
            text: "M&A", //workdayLocationID: "LOCATION-341"
          },
          {
            key: 2,
            value: 2,
            text: "Jamaica - Teleperformance", //workdayLocationID: "LOC-208"
          },
          {
            key: 3,
            value: 3,
            text: "Ohio - Teleperformance", //workdayLocationID: "LOC-043"
          },
        ];
      }
      case supplierTIG: {
        return [
          {
            key: 1,
            value: 1,
            text: "Durban – South Africa", //workdayLocationID: "LOC-042"
          },
          {
            key: 2,
            value: 2,
            text: "Pennsylvania - ICX", //workdayLocationID: "LOC-401"
          },
        ];
      }
      default: {
        return [];
      }
    }
  }

  getOptionsFromApiOptions(options) {
    const retOptions = [];

    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        retOptions.push({ key, value: key, text: options[key] });
      }
    }

    return retOptions;
  }

  render() {
    const { contractContainer } = this.props;
    const { fetchingSuppliers, startContract } = this.props.contractContainer.state;
    const groupDefaultFields = [];
    let showMissingValues = false;
    let missingValues = false;
    const submitting = contractContainer.state.startContract.submitting;

    startContract.fields.forEach((field, i) => {
      let fieldComponent;

      if (["uniqueId", "firstName", "lastName", "workEmailAddress"].includes(field.key)) {
        // not editing these fields for the whole group
        return;
      }

      switch (field.fieldType) {
        case "select":
          fieldComponent = (
            <Form.Dropdown
              placeholder={`${field.display}...`}
              search
              selection
              options={this.getOptionsFromApiOptions(field.options)}
              onChange={(e, data) => contractContainer.setFieldValue(field.key, data.value)}
              value={startContract.fields.find((f) => f.key === field.key).value}
            />
          );
          break;
        case "text":
          fieldComponent = (
            <Form.Input
              placeholder={`${field.display}...`}
              value={field.value}
              onChange={(e, data) => contractContainer.setFieldValue(field.key, data.value)}
            />
          );
          break;
        case "date":
          fieldComponent = (
            <DatePicker
              selected={field.value}
              onChange={(e, data) => contractContainer.setFieldValue(field.key, data.value)}
            />
          );
          break;
        default:
          break;
      }

      groupDefaultFields.push(
        <div key={i} className="field-group">
          <p>
            <span className={field.isRequired ? "required" : ""}>{field.display}</span>
          </p>
          {fieldComponent}
        </div>
      );
    });

    let selectedSupplier;
    if (startContract.supplierId) {
      selectedSupplier = contractContainer.state.suppliers.find((supplier) => {
        return supplier.id === startContract.supplierId;
      });
    }

    let hideSubmit = startContract.profileId === 0 || startContract.phoneSystemId === 0 || startContract.locationId === 0;

    const worker = startContract.ui.worker;
    const isAddWorkerFormComplete =
      worker &&
      worker.uniqueId &&
      worker.firstName &&
      worker.lastName &&
      worker.jobProfileID &&
      worker.hrhcOrganizationId;
    const disableModalButton = !isAddWorkerFormComplete;

    return (
      <StyledOverview css={styles}>
        <Form>
          <StyledHeader as="h2">Add Contingent Workers</StyledHeader>
          {submitting && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          {fetchingSuppliers ? (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          ) : (
            <div>
              <Form.Dropdown
                placeholder="Select Contract Group"
                search
                selection
                options={this.getSupplierOptions()}
                onChange={this.handleSupplierChange}
                value={startContract.ui.supplierId}
              />
              {selectedSupplier && (
                <Form.Dropdown
                  placeholder="Select Profile"
                  search
                  selection
                  options={this.getProfileOptions()}
                  onChange={this.handleProfileChange}
                  value={this.props.contractContainer.state.startContract.profileId || null}
                />
              )}
              {selectedSupplier && (
                <Form.Dropdown
                  placeholder="Select Phone System"
                  search
                  selection
                  options={this.getPhoneSystemOptions(startContract.profileId)}
                  onChange={this.handlePhoneSystemChange}
                  value={this.props.contractContainer.state.startContract.phoneSystemId || null}
                />
              )}
              {selectedSupplier && (
                <Form.Dropdown
                  placeholder="Select Location"
                  search
                  selection
                  options={this.getLocationOptions(selectedSupplier.id)}
                  onChange={this.handleLocationChange}
                  value={this.props.contractContainer.state.startContract.locationId || null}
                />
              )}
              {startContract.showConfirmSupplier && (
                <Confirm
                  content="Are you sure you want to change supplier? Changes to the current form may be lost."
                  open={startContract.showConfirmSupplier}
                  onCancel={this.handleCancelConfirmSupplier}
                  onConfirm={this.handleConfirmSupplier}
                />
              )}
              {startContract.supplierId && !startContract.fetchingFields && (
                <h4>{`Contract Group: ${selectedSupplier.name}`}</h4>
              )}
              {startContract.supplierId && startContract.fetchingFields && (
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>
              )}
              {startContract.supplierId && !startContract.fetchingFields && (
                <div>
                  {groupDefaultFields.length > 0 && (
                    <Segment id="group-fields">
                      <h4>Default Worker Properties</h4>
                      <p>
                        These fields control the default properties to set for the new group of
                        workers uploaded below.{" "}
                        <strong>You can edit fields after uploading the csv.</strong>
                      </p>
                      {groupDefaultFields}
                    </Segment>
                  )}
                  <p>
                    Please upload a csv file of unique employee data, based on the the below
                    template, or upload an individual worker's data by clicking Add Worker. If
                    uploading a file, it must be saved as a csv.
                  </p>
                  <a href={CSVTemplate} id="csv-template" className="ui blue button">
                    Download Worker Data Template
                  </a>
                  <div className="file-input-container">
                    <span>
                      <input
                        id="input-files-button"
                        type="file"
                        onChange={this.handleFileChange}
                        ref={this.fileInput}
                      />
                    </span>
                    <span>OR</span>
                    <Button
                      className="add-worker-row-button"
                      size="small"
                      onClick={this.handleAddWorkerClick}
                    >
                      Add Worker
                    </Button>
                  </div>
                  {startContract.workers.length > 0 && (
                    <div>
                      <p>
                        Please review the worker data below, and edit as needed, before submitting
                        request to contract the new contingent worker(s).
                      </p>
                      <Segment className="table-container">
                        <Table celled textAlign="center">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell key="0" />
                              {startContract.fields.map((field, i) => (
                                <Table.HeaderCell key={i + 1}>{field.display}</Table.HeaderCell>
                              ))}
                              <Table.HeaderCell key="10000" />
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {startContract.workers.map((worker, i) => (
                              <Table.Row key={i}>
                                <Table.Cell key="0">
                                  <span
                                    onClick={() =>
                                      contractContainer.openWorkerEditForm(worker.uniqueId)
                                    }
                                  >
                                    <Edit className="icon edit-icon" color="#2F3061" />
                                  </span>
                                </Table.Cell>
                                {startContract.fields.map((field, j) => {
                                  const value = worker[field.key];
                                  let displayedValue;

                                  if (field.fieldType === "select") {
                                    displayedValue = field.options[value];
                                  } else {
                                    displayedValue = value;
                                  }

                                  const missingValue =
                                    startContract.showMissingValues && field.isRequired && !value;

                                  if (missingValue) {
                                    missingValues = true;
                                  }

                                  return (
                                    <Table.Cell
                                      key={j + 1}
                                      className={missingValue ? "missing-value" : ""}
                                    >
                                      {displayedValue}
                                    </Table.Cell>
                                  );
                                })}
                                <Table.Cell key="10000">
                                  <X
                                    className="icon x-icon"
                                    color="#B2292E"
                                    onClick={() =>
                                      contractContainer.removeStagedWorker(worker.uniqueId)
                                    }
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                        <Button
                          className="add-worker-row-button"
                          size="small"
                          onClick={this.handleAddWorkerClick}
                        >
                          Add Worker
                        </Button>
                      </Segment>
                      {startContract.showMissingValues && missingValues && (
                        <p className="missing-values-alert">Please enter missing values.</p>
                      )}
                      <Confirm
                        content="Are you sure you want to submit?"
                        open={startContract.showConfirmSubmit}
                        onCancel={this.handleCancelConfirm}
                        onConfirm={this.handleSubmitConfirm}
                      />
                      <Button
                        onClick={this.handleSubmitClick}
                        disabled={submitting || hideSubmit}
                        color="green"
                      >
                        {submitting ? "Submitting..." : "Submit"}
                      </Button>
                    </div>
                  )}
                  <Divider section id="start-contract-form-report-divider" />
                  {startContract.pendingWorkers.length > 0 && startContract.fields.length > 0 && (
                    <Segment>
                      <h4>{`${selectedSupplier.name} workers pending approval in Workday`}</h4>
                      <Table celled textAlign="center">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell key="1">{`${selectedSupplier.name} Unique ID`}</Table.HeaderCell>
                            <Table.HeaderCell key="2">First Name</Table.HeaderCell>
                            <Table.HeaderCell key="3">Last Name</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {!!(
                            startContract.pendingWorkers && startContract.pendingWorkers.length
                          ) &&
                            startContract.pendingWorkers.map((worker, i) => {
                              return (
                                <Table.Row key={i}>
                                  <Table.Cell key="1">
                                    {contractContainer.getUniqueId(worker)}
                                  </Table.Cell>
                                  <Table.Cell key="2">{worker.EmployeeName.First}</Table.Cell>
                                  <Table.Cell key="3">{worker.EmployeeName.Last}</Table.Cell>
                                </Table.Row>
                              );
                            })}
                        </Table.Body>
                      </Table>
                    </Segment>
                  )}
                </div>
              )}
            </div>
          )}
        </Form>

        <Modal
          open={!!startContract.ui.worker}
          onClose={this.closeWorkerEditForm}
          dimmer="inverted"
          css={styles}
        >
          <Modal.Header>{startContract.showWorkerAddForm ? "Add" : "Edit"} Worker</Modal.Header>
          <Modal.Content>
            {!!startContract.ui.worker &&
              startContract.fields.map((field, i) => {
                let fieldComponent;

                switch (field.fieldType) {
                  case "select":
                    fieldComponent = (
                      <Form.Dropdown
                        placeholder={`${field.display}...`}
                        search
                        selection
                        options={this.getOptionsFromApiOptions(field.options)}
                        onChange={(e, data) =>
                          contractContainer.setWorkerFieldValue(field.key, data.value)
                        }
                        value={startContract.ui.worker[field.key]}
                      />
                    );
                    break;
                  case "text":
                    fieldComponent = (
                      <Form.Input
                        placeholder={`${field.display}...`}
                        value={startContract.ui.worker[field.key]}
                        onChange={(e, data) =>
                          contractContainer.setWorkerFieldValue(field.key, data.value)
                        }
                      />
                    );
                    break;
                  case "date":
                    fieldComponent = (
                      <DatePicker
                        selected={startContract.ui.worker[field.key]}
                        onChange={(e, data) =>
                          contractContainer.setWorkerFieldValue(field.key, data.value)
                        }
                      />
                    );
                    break;
                  default:
                    break;
                }

                const showMissingValue =
                  startContract.showWorkerMissingValues &&
                  field.isRequired &&
                  (!startContract.ui.worker[field.key] ||
                    !startContract.ui.worker[field.key].trim());

                if (showMissingValue) {
                  showMissingValues = true;
                }

                return (
                  <Form.Group key={i} className="field-group">
                    <p>
                      <span
                        className={classNames({
                          required: field.isRequired,
                          "missing-value": showMissingValue,
                        })}
                      >
                        {field.display}
                      </span>
                    </p>
                    {fieldComponent}
                  </Form.Group>
                );
              })}
          </Modal.Content>
          <Modal.Actions>
            {showMissingValues && (
              <p className="missing-values-alert">Please enter missing values.</p>
            )}
            <Button color="grey" onClick={this.handleCloseWorkerEditForm}>
              Discard Changes and Close
            </Button>
            <Button
              disabled={disableModalButton}
              color="green"
              onClick={this.handleSaveWorkerClick}
            >
              Save and Close
            </Button>
          </Modal.Actions>
        </Modal>
      </StyledOverview>
    );
  }
}

export default class ContractContingentWorkersWrapper extends Component {
  render() {
    return (
      <Subscribe to={[ContractContainer]}>
        {(contractContainer) => <ContractContingentWorkers contractContainer={contractContainer} />}
      </Subscribe>
    );
  }
}
