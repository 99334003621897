import React from "react";
import styled from "styled-components";
import { Search } from "react-feather";
import { Form } from "react-bootstrap";

const StyledSearchInput = styled.div`
  display: flex;
  align-items: right;
  justify-content: flex-end;
  flex: 1 0 auto;
  position: relative;
  padding: 0.5rem 0;
  svg {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    width: 18px;
    height: auto;
    color: ${props => props.theme.colors.primaryShades[1]};
  }
  input.form-control {
    padding-left: 2.5rem !important;
    /* min-width: 420px; */
    font-weight: ${props => props.theme.fonts.bodyWeight};
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

const SearchInput = ({ placeholder, value, disabled, onChange }) => (
  <StyledSearchInput>
    <Search />
    <Form.Control
	  style={{ height: '100%' }}
      type="search"
      placeholder={placeholder}
      name="search"
      value={value}
      disabled={disabled}
      onChange={onChange}
      autoComplete={"off"}
    />
  </StyledSearchInput>
);

SearchInput.defaultProps = {
  placeholder: "Filter through workers...",
  disabled: false,
  value: "",
  onChange: e => null
};

export default SearchInput;
