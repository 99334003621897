import { keyframes } from "styled-components";

// Folding Cube Loading Animation
const foldingCube = keyframes`
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
`;

// Wandering Cube Animation
const wanderingCubes = keyframes`
  0% {
    transform: rotate(0deg)
  }
  25% {
    transform: translateX(200%) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(200%) translateY(200%) rotate(-179deg);
  }
  50.1% {
    transform: translateX(200%) translateY(200%) rotate(-180deg);
  }
  75% {
    transform: translateX(0) translateY(200%) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

// Heartbeat for Footer
const heartbeat = keyframes`
  0% {
    transform: scale(1);
  }
  50%{
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
`;

// Spinning Loader for ButtonLoader
const loaderSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Duration Bar for Toasts
const toastDuration = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

// Slide In (Login/Panels)
const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

// Slide Out (Login/Panels)
const slideOut = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;

// Fade In (Login/Panels)
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: .8;
  }
`;

// Fade Out (Login/Panels)
const fadeOut = keyframes`
  0% {
    opacity: .8;
  }
  100% {
    opacity: 0;
  }
`;

export {
  foldingCube,
  wanderingCubes,
  heartbeat,
  loaderSpin,
  toastDuration,
  slideIn,
  slideOut,
  fadeIn,
  fadeOut
};
