import React, { useState, useContext } from "react";
import { ID, getRandomMessage, toastMessages } from "./helpers";

export const ToastsContext = React.createContext();
export const useToasts = () => useContext(ToastsContext);

export const ToastsProvider = ({ children }) => {
  let [toasts, setToasts] = useState([]);

  // Display Toast
  function displayToast({ title, body, type = "default", expires = true }) {
    setToasts(
      toasts.concat({
        id: ID(),
        title: title || getRandomMessage(toastMessages[type]),
        body,
        type,
        expires
      })
    );
  }

  // Dismiss/Remove Toast
  function dismissToast(id) {
    let toastsList = toasts.filter(toast => toast.id !== id);
    setToasts(toastsList);
  }

  return (
    <ToastsContext.Provider value={{ toasts, displayToast, dismissToast }}>
      {children}
    </ToastsContext.Provider>
  );
};
