const env = process.env['NODE_ENV'] || 'development';

const configs = {
  development: {
    CONTRACT_API_URL: 'https://contract-hcm.hr-dev.rvapps.io',
    AUTH_DOMAIN: 'redventures-dev.auth0.com',
    AUTH_CLIENT_ID: 'oHa2GKX0fdy3YTB1H7isZbUsM4iFdall',
    AUTH_AUDIENCE: 'dev-staffing-portal-api-resource',
    AUTH_STORAGE_KEY: 'staffingAccessToken',
    ACCESS_GROUPS: [],
  },
  production: {
    CONTRACT_API_URL: 'https://contract-hcm.hr-prod.rvapps.io',
    AUTH_DOMAIN: 'redventures-prod.auth0.com',
    AUTH_CLIENT_ID: 'Y8FjcBa9iyQKJqxOH881K7ri7XergGfO',
    AUTH_AUDIENCE: 'prod-staffing-portal-api-resource',
    AUTH_STORAGE_KEY: 'staffingAccessToken',
    ACCESS_GROUPS: [],
  },
};

export default configs[env];
