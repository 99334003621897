import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import * as serviceWorker from './util/serviceWorker';
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { GlobalStyle } from "./styles/components";
import { Auth0Provider } from "./util/auth";
import { ToastsProvider } from "./util/toasts";

import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

// Layout Imports
import ApplicationLayout from "./components/Layout/ApplicationLayout";
import ScrollToTop from "./components/Layout/ScrollToTop";
import Authentication from "./components/Layout/Authentication";

// https://facebook.github.io/create-react-app/docs/adding-bootstrap
import "./styles/bootstrap.css";

import Dashboard from './pages/Dashboard';
import ContractContingentWorkers from './pages/ContractContingentWorkers';
import ManageContingentWorkers from './pages/ManageContingentWorkers';

const UNSTATED = require('unstated-debug');

UNSTATED.logStateChanges = true;

const App = () => (
  <ThemeProvider theme={theme}>
    <ToastsProvider>
      <Auth0Provider>
        <Authentication>
          <BrowserRouter>
            <ScrollToTop>
              <ApplicationLayout>
                <GlobalStyle />
                <Switch>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/startcontracts" component={ContractContingentWorkers} />
                  <Route exact path="/contractworkers" component={ManageContingentWorkers} />
                  <Redirect exact from="/" to="dashboard" />
                </Switch>
              </ApplicationLayout>
            </ScrollToTop>
          </BrowserRouter>
        </Authentication>
      </Auth0Provider>
    </ToastsProvider>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
