// src/react-auth0-wrapper.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import jsCookie from "js-cookie";
import jwtDecode from "jwt-decode";
import queryString from "query-string";
import { useToasts } from "./toasts";
import configs from '../util/config';

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider = ({ children }) => {
  // Get Our Toasts
  const { displayToast } = useToasts();

  const [auth, setAuth] = useState({
    client: null,
    loading: true,
    isAuthenticated: jsCookie.get(configs.AUTH_STORAGE_KEY) ? true : false
  });

  // Are we animating?
  const [animation, setAnimation] = useState(
    jsCookie.get(configs.AUTH_STORAGE_KEY) ? "done" : "not-started"
  );

  useEffect(() => {
    if (animation === "not-started" && auth.isAuthenticated && !auth.loading) {
      // Start Animation
      setAnimation("animating");
      setTimeout(() => {
        setAnimation("done");
      }, 750);
    }
  }, [animation, auth]);

  // Custom Login Function so we can set loading to true when button us clicked
  function handleLogin(...p) {
    setAuth({
      ...auth,
      loading: true
    });
    auth.client.loginWithRedirect(...p);
  }

  function handleLogout(...p) {
    jsCookie.remove(configs.AUTH_STORAGE_KEY);
    auth.client.logout(...p);
  }

  useEffect(() => {
    const initAuth0 = async () => {
      // Start Loading Animation
      setAuth({
        ...auth,
        loading: true
      });
      const auth0 = await createAuth0Client({
        domain: `${configs.AUTH_DOMAIN}`,
        client_id: `${configs.AUTH_CLIENT_ID}`,
        audience: `${configs.AUTH_AUDIENCE}`,
        redirect_uri: window.location.origin,
        connection: "okta"
      });

      if (window.location.search.includes("code=")) {
        await auth0.handleRedirectCallback();
        displayToast({
          title: "Welcome!",
          body: "You have logged in successfully.",
          type: "success"
        });
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      } else if (window.location.search.includes("error=")) {
        // Query Data for Queried business
        let parsed = queryString.parse(window.location.search);
        displayToast({
          title: parsed.error,
          body: parsed.error_description,
          type: "error"
        });
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }

      const isAuthenticated = await auth0.isAuthenticated();

      if (isAuthenticated) {
        const token = await auth0.getTokenSilently();
        const parsedToken = jwtDecode(token);
        // Set Token to Storage with the token's expiration from Auth.
        jsCookie.set(configs.AUTH_STORAGE_KEY, token, { expires: new Date(parsedToken.exp * 1000) });

        // Set Auth Client
        setAuth({
          loading: false,
          client: auth0,
          isAuthenticated,
          token,
          user: {
            name: {
              first: parsedToken["https://firstName"],
              last: parsedToken["https://lastName"]
            },
            employeeID: parsedToken["https://employeeId"],
            email: parsedToken["https://email"],
            jobFamily: parsedToken["https://jobFamily"],
            scopes: parsedToken.scope.split(" ")
          }
        });
      } else {
        jsCookie.remove(configs.AUTH_STORAGE_KEY);
        setAuth({
          loading: false,
          client: auth0,
          isAuthenticated,
          token: null,
          user: null
        });
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        animation: animation,
        isAuthenticated: auth.isAuthenticated,
        user: auth.user,
        token: auth.token,
        // Consider the Application loading if either of these are yet to be resolved
        loading: auth.loading,
        getIdTokenClaims: (...p) => auth.client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth.client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth.client.getTokenWithPopup(...p),
        logout: (...p) => handleLogout(...p),
        login: (...p) => handleLogin(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
