import { Home, Plus, Users } from "react-feather";

/*
 ** Navigation Menu Object for Reference
 */
const navigationLinks = [
  {
    path: "/dashboard/",
    title: "Dashboard",
    base: "dashboard",
    icon: Home
  },
  {
    path: "/startcontracts/",
    title: "Add Contingent Workers",
    base: "startcontracts",
    icon: Plus
  },
  {
    path: "/contractworkers/",
    title: "View/Manage Contingent Workers",
    base: "contractworkers",
    icon: Users
  },
];

export { navigationLinks };
