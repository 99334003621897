// Generate unique IDs for use as pseudo-private/protected names.
// Similar in concept to
// <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
//
// The goals of this function are twofold:
//
// * Provide a way to generate a string guaranteed to be unique when compared
//   to other strings generated by this function.
// * Make the string complex enough that it is highly unlikely to be
//   accidentally duplicated by hand (this is key if you're using `ID`
//   as a private/protected name on an object).
//
var ID = function() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

/*
 ** Alphabetize by Key
 */
function alphabetize(array, key) {
  return array.sort((a, b) => {
    return a[key].localeCompare(b[key]);
  });
}

/*
 ** Chunk an Array into Sizes
 */
function chunkArray(input, size) {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
}

// Check if between two integers
function between(op, target, range) {
  return op < target + range && op > target - range;
}

/*
 ** Loading Messages
 */
const loadingMessages = [
  "We're thinking really hard",
  "They see me loading, They waiting",
  "Recombobulating Discombobulators",
  "Initiating launch sequence",
  "Is this thing on?",
  "Do you even notice these?",
  "*Elevator Music Plays*",
  "Stopping to smell the flowers",
  "Now with scratch and sniff"
];

/*
 ** Error Messages
 */
const errorMessages = [
  "Bummer!",
  "Uh oh...",
  "Something isn't quite right.",
  "Well this is awkward."
];

/*
 ** No Results Messages
 */
const noResultsMessages = [
  "No matching results.",
  "We came. We saw. We found nothing.",
  "A witty quote about finding nothing of value.",
  "Hey, we tried our best to find something. OK? You do better.",
  "Silence really is golden. Unless you were trying to find something. Sorry."
];

/*
 ** Toast Messages
 */
const toastMessages = {
  success: ["Awesome!", "Nice!", "Sweet!"],
  error: errorMessages,
  default: ["Notification", "Here's an Update"]
};

// Get Random Message from an Array
function getRandomMessage(messages) {
  return messages[Math.floor(Math.random() * messages.length)];
}

export {
  ID,
  alphabetize,
  between,
  chunkArray,
  loadingMessages,
  errorMessages,
  toastMessages,
  noResultsMessages,
  getRandomMessage
};
