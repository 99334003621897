import styled, { css, createGlobalStyle } from "styled-components";
import { Card, Form } from "react-bootstrap";

// Global Style
// These all have the !important property because the bootstrap css
// is loaded after styled components in development and will override these.
// Styled Components properly loads after bootstrap on build.
const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => props.theme.colors.textColor} !important;
    font-family: ${props => props.theme.fonts.bodyFamily} !important;
    font-weight: ${props => props.theme.fonts.bodyWeight} !important;
    background: ${props => props.theme.colors.whiteGrey} !important;
  }
`;

/*
 ** Reusable CSS
 */
const CoverParent = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.white};
  z-index: 10;
`;

// Center Element
const AbsoluteCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(45deg);
`;

/*
 ** Reusable Styled Components
 */

// Styled Header
const StyledHeader = styled.header`
  font-family: ${props => props.theme.fonts.headerFamily};
  font-weight: ${props => props.theme.fonts.headerWeight};

  ${props =>
  props.flex && {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }}

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .action + .action {
      margin-left: 1rem;
    }
  }

  .selection {
    font-size: 1rem;
  }
`;

// Styled Paragraph
const StyledParagraph = styled.p`
  font-family: ${props => props.theme.fonts.bodyFamily};
  font-weight: ${props => props.theme.fonts.bodyWeight};
  text-transform: ${props => (props.capitalize ? "capitalize" : "inherit")};

  strong {
    font-weight: bolder;
  }
`;

// Background Sections
const StyledSection = styled.section`
  background: ${props =>
  props.noBackground ? "transparent" : props.theme.colors.whiteGrey};
  padding: ${props => (props.noPadding ? "0" : "1.5rem")};
`;

const StyledOverview = styled.div`
  position: relative;
  padding: 2rem 1.5rem;
  min-height: 230px;
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  h5 {
    text-transform: uppercase;
  }
  .container-fluid {
    padding: 0;
  }
  strong {
    font-weight: 600;
  }
`;

// Styled Cards
const StyledCard = styled(Card)`
  /* Add Specificity to Override Bootstrap Styles */
  && {
    border-radius: 5px;
    border-color: ${props => props.theme.colors.borderColor};

    &.padded {
      padding: 1.5rem 0.5rem;
    }

    &.shadowed {
      box-shadow: ${props => props.theme.materialShadows[1]};
    }

    & + & {
      margin-top: 1.5rem;
    }

    /* Header Overrides */
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: ${props => props.theme.colors.whiteGrey};
      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      ${StyledHeader} {
        margin: 0;
        text-transform: uppercase;
      }
    }
  }
`;

// Styled Form
const StyledForm = styled(Form)`
  label {
    text-transform: uppercase;
    color: ${props => props.theme.colors.mute};
    letter-spacing: 1px;
    font-family: ${props => props.theme.fonts.headerFamily};
    font-weight: ${props => props.theme.fonts.headerWeight};
  }

  .form-group + ${StyledHeader}, .form-group + .form-group:not(.col),
  .form-group + .form-row {
    margin-top: 2rem;
  }

  label:not(.action) {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
`;

// StickyHeader
const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: ${props => props.theme.colors.white};
  height: 70px;
  z-index: 9;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};

  ${StyledHeader} {
    padding: 0 1.5rem;
    font-size: 1.25rem;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .action {
    border-left: 1px solid ${props => props.theme.colors.lightGrey};
    /* & + .action {
      margin-left: 1rem;
    } */
  }

  .button-action {
    height: 70px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const InnerPage = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  height: calc(100vh - ${props => props.theme.navbarHeight});
  overflow-y: auto;
`;

const FlexHeight = styled(InnerPage)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

/*
 ** Lists & Sub Components
 */
const ListSection = styled.div.attrs(props => ({
  subtraction: props.subtraction || "0px"
}))`
  position: absolute;
  top: ${props => props.subtraction};
  left: 0;
  width: 100%;
  height: calc(100% - ${props => props.subtraction});
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ListItem = styled.div`
  position: relative;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  border-radius: 0.5rem;
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in;
  box-shadow: ${props => props.theme.materialShadows[1]};
  color: ${props => props.theme.colors.textColor};
  max-width: 100%;
  overflow: hidden;
  height: 76px;

  .content {
    flex: 1 0 auto;
    max-width: calc(100% - 0.5rem);
  }

  &:hover {
    cursor: pointer;
    box-shadow: ${props => props.theme.materialShadows[3]};

    /* Because of Link Styles */
    color: ${props => props.theme.colors.textColor};
    text-decoration: none;
  }

  ${StyledHeader} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${StyledParagraph} {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .meta,
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .meta {
    flex: 1 0 auto;
  }

  .item {
    min-width: 200px;
    font-size: 0.9rem;
    margin-left: 1.5rem;
    color: ${props => props.theme.colors.darkGrey};
    svg {
      height: 1rem;
      width: auto;
      margin-left: 0.5rem;
    }

    &.positive {
      color: #5a9e5a;
    }

    &.negative {
      color: #c36672;
    }

    &.highlight {
      color: ${props => props.theme.colors.primaryShades[1]};
    }
  }
`;

const StatusIcon = styled.span.attrs(props => ({
  height: props.dot ? "0.5rem" : "60px"
}))`
  display: inline-block;
  height: ${props => props.height};
  width: 0.5rem;
  border-radius: 1rem;
  background-color: ${props => props.theme.colors.statuses[props.status]};
  margin-right: 1rem;
`;

const EmployeeAvatar = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.mediumGrey};
  background-image: url(https://slack-commands.corp.rvapps.io/employee/image/${props => props.employeeID}.jpg);
  background-size: cover;
  background-position: center center;
  margin-right: 1rem;
`;

export {
  GlobalStyle,
  CoverParent,
  AbsoluteCenter,
  StyledHeader,
  StyledParagraph,
  StyledSection,
  StyledOverview,
  StyledCard,
  StyledForm,
  StickyHeader,
  InnerPage,
  FlexHeight,
  ListSection,
  ListItem,
  EmployeeAvatar,
  StatusIcon
};
