/*
 ** Responsive Breakpoints
 ** https://getbootstrap.com/docs/4.0/layout/grid/#grid-options
 */
const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  sl: "1400px" // Super Large, Additional Breakpoint
};

/*
 ** Main Theme Object
 */
const theme = {
  // Primary Colors
  colors: {
    primary: "#0D2437",
    primaryShades: {
      0: "#12314D",
      1: "#1E5280",
      2: "#3082CC",
      3: "#8FCAFF",
      4: "#CCE7FF"
    },
    primaryGradient: `linear-gradient(
      150deg,
      #0D2437 15%,
      #1E5280 94%
    )`,
    statuses: {
      1: "#FF8E72", // Waiting
      2: "#1E5280", // In Progress
      3: "#662E9B", // Reassigned
      4: "#008F00", // Complete
      5: "#FFCC00", // On Hold
      6: "#D2001C" // Technical Issue
    },
    redventures: "#B62032",
    textColor: "#11151D",
    successColor: "#1ead1e",
    dangerColor: "#d0474c",
    cautionColor: "#EC9928",
    borderColor: "#E5E5E5",
    darkGrey: "#8D8D8F",
    mediumGrey: "#DADADA",
    lightGrey: "#F2F5F7",
    whiteGrey: "#FBFCFD",
    white: "#FFFFFF",
    opaque: "rgba(255,255,255, .95)",
    mute: "#5C5D5D"
  },
  // Fonts
  baseSize: "16px",
  fonts: {
    bodyFamily: '"Muli", sans-serif',
    bodyWeight: "300",
    headerFamily: '"Poppins", sans-serif',
    headerWeight: "500"
  },
  // Breakpoints
  breakpoints: breakpoints,
  // Layout Breakpoints
  sidebarWidth: "240px",
  sidebarCollapsedWidth: "68px",
  navbarHeight: "65px",
  // Shadow
  shadow: "0 2px 4px 0 rgba(0, 0, 0, .1)",
  materialShadows: {
    0: "none",
    1: "0 1px 3px 0 rgba(48, 49, 51, .1)",
    2: "0 2px 4px 0 rgba(48, 49, 51, .1)",
    3: "0 4px 8px 0 rgba(48, 49, 51, .1)",
    4: "0 8px 16px 0 rgba(48, 49, 51, .1)",
    5: "0 16px 24px 0 rgba(48, 49, 51, .1)"
  }
};

export { breakpoints, theme };
