import React, { useState, Fragment } from "react";
import styled from "styled-components";
import SiteHeader from "./SiteHeader";
import Sidebar from "./Sidebar";
import { Provider } from "unstated";
import { ToastContainer } from 'react-toastify';

const StyledLayout = styled.div`
  transition: padding 0.25s ease-in-out;
  padding: ${props =>
  props.collapsed
    ? `${props.theme.navbarHeight} 0 0 ${props.theme.sidebarCollapsedWidth}`
    : `${props.theme.navbarHeight} 0 0 ${props.theme.sidebarWidth}`};
  background-color: ${props => props.theme.colors.white};
`;

function ApplicationLayout({ children }) {
  // Initial State
  let [collapsed, setCollapsed] = useState(false);
  return (
    <Provider>
      <ToastContainer autoClose={false} />
      <Fragment>
        <SiteHeader />
        <Sidebar
          collapsed={collapsed}
          toggleCollapse={() => setCollapsed(!collapsed)}
        />
        <StyledLayout collapsed={collapsed}>{children}</StyledLayout>
      </Fragment>
    </Provider>
  );
}

export default ApplicationLayout;
