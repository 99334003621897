import React, { Component } from "react";
import { Button, Confirm, Dimmer, Form, Loader, Table, Checkbox } from "semantic-ui-react";
import { Subscribe } from "unstated";
import autobind from "react-autobind";
import ContractContainer from "../containers/ContractContainer";
import { StyledHeader, StyledOverview } from "../styles/components";
import SearchInput from "../components/SearchInput";
import DateSelect from "../components/DateSelect";
import moment from "moment";
import XLSX from "xlsx";

const selectStatusPending = "pending";
const selectStatusNotPending = "non-pending";
const today = new Date();

class ManageContingentWorkers extends Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      selectAll: false,
    };
  }

  componentDidMount() {
    const { contractContainer } = this.props;
    if (contractContainer.state.suppliers.length === 0) {
      contractContainer.fetchSuppliers();
    }
  }

  handleSupplierChange(e, data) {
    const { contractContainer } = this.props;
    const { manageContracts } = this.props.contractContainer.state;
    const newSupplierId = data.value;

    if (newSupplierId && newSupplierId !== manageContracts.supplierId) {
      contractContainer.setManageContractsSupplierId(newSupplierId);
    }
  }

  selectAll(isSelected) {
    const { manageContracts } = this.props.contractContainer.state;
    manageContracts.workers = manageContracts.workers.map((worker) => {
      return {
        ...worker,
        Selected: isSelected,
      };
    });
    manageContracts.pendingWorkers = manageContracts.pendingWorkers.map((worker) => {
      return {
        ...worker,
        Selected: isSelected,
      };
    });

    this.setState({
      selectAll: isSelected,
    });

    this.props.contractContainer.setState({
      manageContracts: manageContracts,
    });
  }

  handleFilterChange(e) {
    const { manageContracts } = this.props.contractContainer.state;

    this.props.contractContainer.setState({
      manageContracts: {
        ...manageContracts,
        filter: e.target.value.toLowerCase(),
      },
    });
  }

  handleSort = (clickedColumn) => {
    const { manageContracts } = this.props.contractContainer.state;
    const { sort } = manageContracts;
    const { column, direction } = sort;

    // if clicking a new column, automatically sort by ascending and return
    if (column !== clickedColumn) {
      this.props.contractContainer.setState({
        manageContracts: {
          ...manageContracts,
          sort: {
            column: clickedColumn,
            direction: "ascending",
          },
          multiSelect: {
            lastBoxClicked: null,
            lastTypeClicked: null,
          },
        },
      });

      return;
    }

    // if clicking the same column again, figure out which way
    // to sort differently based on how we're already sorting
    let newDirection = null;
    if (direction === null) {
      newDirection = "ascending";
    } else if (direction === "ascending") {
      newDirection = "descending";
    }

    this.props.contractContainer.setState({
      manageContracts: {
        ...manageContracts,
        sort: {
          column,
          direction: newDirection,
        },
      },
    });
  };

  handleDateChange(event, date) {
    const { manageContracts } = this.props.contractContainer.state;

    if (date.value !== null) {
      const formattedDate = moment(date.value).format("YYYY-MM-DD");

      manageContracts.pendingWorkers = manageContracts.pendingWorkers.map((worker) => {
        worker.Selected = false;

        return {
          ...worker,
        };
      });

      manageContracts.workers = manageContracts.workers.map((worker) => {
        worker.Selected = worker.OriginalHireDate.substring(0, 10) === formattedDate;

        return {
          ...worker,
        };
      });
    }

    this.props.contractContainer.setState({
      manageContracts: {
        ...manageContracts,
        date: date.value,
      },
    });
  }

  handleLastDayWorkedChange(event, lastDayWorked) {
    const {manageContracts} = this.props.contractContainer.state;

    this.props.contractContainer.setState({
      manageContracts: {
        ...manageContracts,
        lastDayWorked: lastDayWorked.value,
      },
    });
  }

  handleRecreateClick(candidateID) {
    this.props.contractContainer.confirmRecreateWorker(candidateID);
  }

  handleCancelRecreate() {
    this.props.contractContainer.cancelRecreateWorker();
  }

  handleConfirmRecreate() {
    this.props.contractContainer.recreateWorker();
  }

  handleTerminateClick(employeeId) {
    this.props.contractContainer.confirmTerminateWorker(employeeId);
  }

  handleCancelTerminate() {
    this.props.contractContainer.cancelTerminateWorker();
  }

  handleConfirmTerminate() {
    this.props.contractContainer.terminateWorker();
  }

  handleExport() {
    const { manageContracts } = this.props.contractContainer.state;
    const workers = manageContracts.workers
      .filter((worker) => worker.Selected)
      .map((worker) => {
        return [
          `${worker.LegalName.First} ${worker.LegalName.Last}`,
          worker.Username,
          "ConcertUser",
          worker.AgentID,
          worker.EmployeeID,
        ];
      });
    const pendingWorkers = manageContracts.pendingWorkers
      .filter((worker) => worker.Selected)
      .map((worker) => {
        return [
          `${worker.EmployeeName.First} ${worker.EmployeeName.Last}`,
          worker.Username,
          "ConcertUser",
          "",
          "",
        ];
      });
    const data = [
      ["User Name", "User Login Id", "Roles", "Agent Id", "Agent Phone Id"],
      ...workers,
      ...pendingWorkers,
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);

    var wscols = [{ wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }];

    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, "workers");
    XLSX.writeFile(wb, "workers.xlsx");
  }

  getSupplierOptions() {
    const { suppliers } = this.props.contractContainer.state;
    return suppliers.map((supplier) => {
      return {
        key: supplier.id,
        value: supplier.id,
        text: supplier.name,
      };
    });
  }

  selectWorker(isSelected, i, t) {
    const { manageContracts } = this.props.contractContainer.state;
    manageContracts.workers[i].Selected = isSelected;
    manageContracts.date = null;
    manageContracts.multiSelect = { lastBoxClicked: i, lastTypeClicked: t };

    this.props.contractContainer.setState({
      manageContracts: manageContracts,
    });
  }

  selectPendingWorker(isSelected, i, t) {
    const { manageContracts } = this.props.contractContainer.state;
    manageContracts.pendingWorkers[i].Selected = isSelected;
    manageContracts.date = null;
    manageContracts.multiSelect = { lastBoxClicked: i, lastTypeClicked: t };

    this.props.contractContainer.setState({
      manageContracts: manageContracts,
    });
  }

  clickWorker(event, isSelected, i) {
    const t = selectStatusNotPending;
    event.stopPropagation();
    if (event.shiftKey) {
      this.multiSelect(isSelected, i, t);
    } else {
      this.selectWorker(isSelected, i, t);
    }
  }

  clickPendingWorker(event, isSelected, i) {
    event.stopPropagation();
    const t = selectStatusPending;
    if (event.shiftKey) {
      this.multiSelect(isSelected, i, t);
    } else {
      this.selectPendingWorker(isSelected, i, t);
    }
  }

  multiSelect(isSelected, i, t) {
    const { manageContracts } = this.props.contractContainer.state;
    const { lastBoxClicked, lastTypeClicked } = manageContracts.multiSelect;

    // if no other worker is picked for multi select
    if (lastBoxClicked === null) {
      if (t === selectStatusPending) {
        this.selectPendingWorker(isSelected, i, t);
      } else {
        this.selectWorker(isSelected, i, t);
      }
      return;
    }

    // if the multi select includes both pending and non-pending workers the
    // current type will not match the last type selected
    if (t !== lastTypeClicked) {
      let curr;
      let start, end;

      // set the boundaries of where to start with pending workers
      // and where to end with non-pending workers
      if (lastTypeClicked === selectStatusPending) {
        start = lastBoxClicked;
        end = i;
      } else {
        start = i;
        end = lastBoxClicked;
      }

      // select all workers (pending and non-pending) between the start and end selected boxes
      for (curr = start; curr < manageContracts.pendingWorkers.length; curr++) {
        manageContracts.pendingWorkers[curr].Selected = isSelected;
      }

      for (curr = 0; curr <= end; curr++) {
        manageContracts.workers[curr].Selected = isSelected;
      }
    } else {
      // set the boundaries for boxes to check of a single type
      let start, end;
      if (lastBoxClicked < i) {
        start = lastBoxClicked;
        end = i;
      } else {
        start = i;
        end = lastBoxClicked;
      }

      // only select necessary boxes depending on type
      if (t === selectStatusPending) {
        for (let curr = start; curr <= end; curr++) {
          manageContracts.pendingWorkers[curr].Selected = isSelected;
        }
      } else {
        for (let curr = start; curr <= end; curr++) {
          manageContracts.workers[curr].Selected = isSelected;
        }
      }
    }

    manageContracts.multiSelect = { lastBoxClicked: i, lastTypeClicked: t };
    this.props.contractContainer.setState({
      manageContracts: manageContracts,
    });
  }

  render() {
    const { contractContainer } = this.props;
    const { fetchingSuppliers, manageContracts } = this.props.contractContainer.state;
    const { sort, filter, date, lastDayWorked } = manageContracts;
    const { column, direction } = sort;

    let selectedSupplier;
    if (manageContracts.supplierId) {
      selectedSupplier = contractContainer.state.suppliers.find((supplier) => {
        return supplier.id === manageContracts.supplierId;
      });
    }

    const confirmRecreateWorker = manageContracts.workers.find(
      (w) => w.PreHireID === manageContracts.confirmRecreateCandidateID
    );

    const confirmTerminateWorker = manageContracts.workers.find(
      (w) => w.EmployeeID === manageContracts.confirmTerminateEEID
    );

    let filteredWorkers = manageContracts.workers.filter((worker) => {
      if (worker.LegalName.First.toLowerCase().includes(filter)) return true;
      if (worker.LegalName.Last !== undefined)
        return worker.LegalName.Last.toLowerCase().includes(filter);
      return false;
    });

    // filter results based on search
    let filteredPendingWorkers = manageContracts.pendingWorkers.filter((worker) => {
      if (worker.EmployeeName.First.toLowerCase().includes(filter)) return true;
      if (worker.EmployeeName.Last !== undefined)
        return worker.EmployeeName.Last.toLowerCase().includes(filter);
      return false;
    });

    // sort results based on sortable columnns
    if (direction !== null && column !== null) {
      filteredWorkers = filteredWorkers.sort((a, b) => {
        if (direction === "ascending") {
          return a[column] > b[column] ? 1 : -1;
        }

        return a[column] < b[column] ? 1 : -1;
      });

      filteredPendingWorkers = filteredPendingWorkers.sort((a, b) => {
        if (direction === "ascending") {
          return a[column] < b[column] ? 1 : -1;
        }

        return a[column] > b[column] ? 1 : -1;
      });
    }

    const exportDisabled = (() => {
      const { manageContracts } = this.props.contractContainer.state;

      return (
        manageContracts.workers.filter((worker) => worker.Selected).length === 0 &&
        manageContracts.pendingWorkers.filter((worker) => worker.Selected).length === 0
      );
    })();

    return (
      <StyledOverview>
        <Form>
          <StyledHeader as="h2">View/Manage Contingent Workers</StyledHeader>
          {fetchingSuppliers ? (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          ) : (
            <div>
              <Form.Dropdown
                placeholder="Select Contract Group"
                search
                selection
                options={this.getSupplierOptions()}
                onChange={this.handleSupplierChange}
                value={manageContracts.supplierId}
              />
              {manageContracts.supplierId && !manageContracts.fetchingWorkers && (
                <h4>{`Contract Group: ${selectedSupplier.name}`}</h4>
              )}
              {manageContracts.supplierId && manageContracts.fetchingWorkers && (
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>
              )}
              {manageContracts.supplierId && !manageContracts.fetchingWorkers && (
                <div>
                  <div style={{ display: "flex" }}>
                    <SearchInput
                      value={manageContracts.filter}
                      onChange={this.handleFilterChange}
                      placeholder="Search workers by name..."
                    />
                    <DateSelect
                      date={date}
                      placeholder={"Select by Hire Date"}
                      onChange={this.handleDateChange}
                    />
                    <div
                      style={{
                        padding: "0.5rem 0",
                        margin: "0",
                        marginLeft: "5px",
                      }}
                    >
                      <Button
                        style={{
                          height: "100%",
                          backgroundColor: "#008000",
                          color: "white",
                          marginRight: "0",
                        }}
                        disabled={exportDisabled}
                        onClick={() => this.handleExport()}
                      >
                        Export
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div className="table-container" style={{ overflow: "auto" }}>
                      <Table celled sortable textAlign="center">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell key="0">
                              <Checkbox
                                checked={this.state.selectAll}
                                onClick={() => this.selectAll(!this.state.selectAll)}
                              />
                            </Table.HeaderCell>
                            <Table.HeaderCell key="1">Pending Approval</Table.HeaderCell>
                            <Table.HeaderCell
                              key="10"
                              sorted={column === "OriginalHireDate" ? direction : null}
                              onClick={() => this.handleSort("OriginalHireDate")}
                            >
                              Hire Date
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              key="2"
                              sorted={column === "EmployeeID" ? direction : null}
                              onClick={() => this.handleSort("EmployeeID")}
                            >
                              Employee ID
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              key="3"
                              sorted={column === "AgentID" ? direction : null}
                              onClick={() => this.handleSort("AgentID")}
                            >
                              Agent ID
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              key="4"
                              sorted={column === "Username" ? direction : null}
                              onClick={() => this.handleSort("Username")}
                            >
                              Username
                            </Table.HeaderCell>
                            <Table.HeaderCell key="5">First Name</Table.HeaderCell>
                            <Table.HeaderCell key="6">Last Name</Table.HeaderCell>
                            <Table.HeaderCell key="7">Unique ID</Table.HeaderCell>
                            <Table.HeaderCell key="8">Job Title</Table.HeaderCell>
                            <Table.HeaderCell key="11">Account Error</Table.HeaderCell>
                            <Table.HeaderCell key="12" />
                            <Table.HeaderCell key="9" />
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {!!(filteredPendingWorkers && filteredPendingWorkers.length) &&
                            filteredPendingWorkers.map((worker) => {
                              return (
                                <Table.Row key={worker.Index}>
                                  <Table.Cell key="0">
                                    <Checkbox
                                      checked={worker.Selected}
                                      onClick={(event) =>
                                        this.clickPendingWorker(
                                          event,
                                          !worker.Selected,
                                          worker.Index
                                        )
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell key="1">Yes</Table.Cell>
                                  <Table.Cell key="10"></Table.Cell>
                                  <Table.Cell key="2" />
                                  <Table.Cell key="3" />
                                  <Table.Cell key="4" />
                                  <Table.Cell key="5">{worker.EmployeeName.First}</Table.Cell>
                                  <Table.Cell key="6">{worker.EmployeeName.Last}</Table.Cell>
                                  <Table.Cell key="7">
                                    {contractContainer.getUniqueId(worker)}
                                  </Table.Cell>
                                  <Table.Cell key="8" />
                                  <Table.Cell key="11" />
                                  <Table.Cell key="12" />
                                  <Table.Cell key="9" />
                                </Table.Row>
                              );
                            })}
                          {!!(filteredWorkers && filteredWorkers.length) &&
                            filteredWorkers.map((worker) => {
                              let jobProfile;
                              const jobProfileField = manageContracts.fields.find(
                                (f) => f.key === "jobProfileID"
                              );
                              if (
                                jobProfileField &&
                                jobProfileField.options &&
                                jobProfileField.options[worker.JobProfileID]
                              ) {
                                jobProfile = jobProfileField.options[worker.JobProfileID];
                              } else {
                                jobProfile = worker.JobProfileID;
                              }

                              return (
                                <Table.Row key={worker.Index}>
                                  <Table.Cell key="0">
                                    <Checkbox
                                      checked={worker.Selected}
                                      onClick={(event) =>
                                        this.clickWorker(event, !worker.Selected, worker.Index)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell key="1">No</Table.Cell>
                                  <Table.Cell key="10">
                                    {worker.OriginalHireDate.substring(0, 10)}
                                  </Table.Cell>
                                  <Table.Cell key="2">{worker.EmployeeID}</Table.Cell>
                                  <Table.Cell key="3">{worker.AgentID}</Table.Cell>
                                  <Table.Cell key="4">{worker.Username}</Table.Cell>
                                  <Table.Cell key="5">{worker.LegalName.First}</Table.Cell>
                                  <Table.Cell key="6">{worker.LegalName.Last}</Table.Cell>
                                  <Table.Cell key="7">
                                    {contractContainer.getUniqueId(worker)}
                                  </Table.Cell>
                                  <Table.Cell key="8">{jobProfile}</Table.Cell>
                                  <Table.Cell key="11">{worker.Error}</Table.Cell>
                                  <Table.Cell key="12">
                                    <Button
                                      style={{
                                        backgroundColor: "#1e5280",
                                        color: "white",
                                      }}
                                      type="button"
                                      onClick={() => this.handleRecreateClick(worker.PreHireID)}
                                      disabled={manageContracts.recreating}
                                    >
                                      Recreate Account
                                    </Button>
                                  </Table.Cell>
                                  <Table.Cell key="9">
                                    <Button
                                      type="button"
                                      onClick={() => this.handleTerminateClick(worker.EmployeeID)}
                                      disabled={manageContracts.terminating}
                                    >
                                      Terminate
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                          {confirmRecreateWorker && (
                            <Confirm
                              content={`Are you sure you want to recreate the accounts for ${confirmRecreateWorker.LegalName.First} ${confirmRecreateWorker.LegalName.Last}?`}
                              open={manageContracts.confirmRecreateCandidateID !== null}
                              onCancel={this.handleCancelRecreate}
                              onConfirm={this.handleConfirmRecreate}
                              confirmButton={manageContracts.recreating ? "Recreating..." : "OK"}
                            />
                          )}
                          {confirmTerminateWorker && (
                            <Confirm
                              content={<DateSelect
                                date={lastDayWorked}
                                maxDate={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)}
                                placeholder={"Select Termination Date"}
                                onChange={this.handleLastDayWorkedChange}
                              />}
                              header={`Are you sure you want to terminate ${confirmTerminateWorker.LegalName.First} ${confirmTerminateWorker.LegalName.Last}?`}
                              open={manageContracts.confirmTerminateEEID !== null}
                              onCancel={this.handleCancelTerminate}
                              onConfirm={this.handleConfirmTerminate}
                              confirmButton={manageContracts.terminating ? "Terminating..." : "OK"}
                            />
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Form>
      </StyledOverview>
    );
  }
}

export default class ManageContingentWorkersWrapper extends Component {
  render() {
    return (
      <Subscribe to={[ContractContainer]}>
        {(contractContainer) => <ManageContingentWorkers contractContainer={contractContainer} />}
      </Subscribe>
    );
  }
}
