import React, { Component } from 'react';
import styled from 'styled-components';
import { StyledHeader, StyledOverview } from "../styles/components";

const StyledDashboard = styled(StyledOverview)`
  max-width: 750px;
`;

class Dashboard extends Component {
  render() {
    return (
      <StyledDashboard>
          <StyledHeader as="h2">Welcome to the Staffing Portal</StyledHeader>
          <p>
            The Staffing Portal is a tool for adding contingent workers and submitting their
            information to the Red Ventures Human Resources (HR) department.{' '}
          </p>
          <p>
            This is the welcome screen for the Staffing Portal application. From here you can
            navigate to other tabs where you can add, manage, and view contingent workers.{' '}
          </p>
          <h3>Main steps for using the Staffing Portal</h3>
          <p>
            This section is a general outline of how to use the Staffing Portal. For more detailed
            instructions, refer to the additional sections.{' '}
          </p>
          <p><strong>Note: Before adding a contractor to be hired, confirm they do not already exist as active within Workday. Also please confirm if they are not a rehired contractor. If they are, please reactivate their previous contractor account and do not contract a new worker.</strong></p>
          <ol>
            <li>Select a contract group. </li>
            <li>
              Enter candidate information.{' '}
              <em>
                Upload or enter candidate information including names and contact information.
              </em>
            </li>
            <li>Edit or delete entries as needed. </li>
            <li>
              Save and submit any additions or changes.{' '}
              <em>
                Refer to <a href="#finishingUp">Finishing up</a>{' '}
                for more information.
              </em>
            </li>
            <li>Use the View/Manage Contingent workers to view and terminate workers.</li>
          </ol>
          <h3>Specify default worker properties</h3>
          <p>
            To specify default worker properties, complete all fields in the{' '}
            <strong>Default Worker Properties</strong> section.{' '}
          </p>
          <h3 id="enterCandidateInfo">Enter candidate information</h3>
          <p>
            There are two ways to enter candidate information,{' '}
            <a href="#enteringCandidateInfoManually">
              manually enter candidates one at a time
            </a>{' '}
            or{' '}
            <a href="#usingCSV">
              upload a CSV file to add multiple candidates
            </a>
            .{' '}
          </p>
          <h4 id="enteringCandidateInfoManually">Entering candidate information manually:</h4>
          <ol>
            <li>
              Select the <strong>Add Contingent Workers</strong> tab.{' '}
            </li>
            <li>Select your contract group from the dropdown. </li>
            <li>
              Select <strong>Add Worker</strong>.
            </li>
            <li>
              Complete the required information in the <strong>Add Worker</strong> window.{' '}
            </li>
            <li>
              Select <strong>Save and Close</strong>.
            </li>
          </ol>
          <h4 id="usingCSV">Using a CSV file to add candidate information:</h4>
          <ol>
            <li>
              Select the <strong>Add Contingent Workers</strong> tab.
            </li>
            <li>Select your contract group from the dropdown.</li>
            <li>
              Specify default worker properties by completing all fields in the{' '}
              <strong>Default Worker Properties</strong> section.
            </li>
            <li>
              Select <strong>Choose File</strong> to upload a spreadsheet. _Refer to{' '}
              <a href="#creatingCSV">Creating a CSV</a> for more
              information on downloading and creating the CSV file.{' '}
            </li>
            <li>
              Choose the CSV file from your folder and select <strong>OK</strong>.{' '}
              <em>The Staffing Portal displays candidate information.</em>
            </li>
          </ol>
          <p>You can now edit or delete candidate entries as needed. </p>
          <h3>Edit candidate information</h3>
          <p>To edit candidate information:</p>
          <ol>
            <li>
              Select the <strong>Edit</strong> icon
              {/* <div class="doc-icon" id="edit-icon" alt="Icon to access edit function." />*/}{' '}
              beside the candidate you want to edit.{' '}
            </li>
            <li>Edit the fields as needed.</li>
            <li>
              Select <strong>Save and Close</strong>.
            </li>
          </ol>
          <h3>Delete a candidate entry</h3>
          <p>
            To delete a candidate, select the <strong>Delete</strong> icon{' '}
            {/* <div class="doc-icon" id="delete-icon" alt="Icon to access edit function." /> */}
            beside the candidate you want to edit.
          </p>
          <h3 id="creatingCSV">Creating a CSV file</h3>
          <p>
            Before you can upload information, you&#39;ll need to create a csv file.
            <br />
            Here&#39;s how:
          </p>
          <ol>
            <li>
              From the <strong>Contract Contingent Workers</strong> tab, select{' '}
              <strong>Download Worker Data Template</strong>.
            </li>
            <li>
              Open the worker data template file using Microsoft Excel or a similar spreadsheet
              program.{' '}
            </li>
            <li>
              Enter information under each header for every candidate you add.{' '}
              <em>All fields are required.</em>
            </li>
            <li>
              Select <strong>Save As</strong> within the spreadsheet program to save the file.{' '}
            </li>
            <li>
              Name the file and choose <strong>.csv</strong> as the file type.{' '}
            </li>
            <li>
              Upload the CSV file to the Staffing Portal.{' '}
              <em>
                Refer to{' '}
                <a href="#enterCandidateInfo">
                  Enter candidate information
                </a>{' '}
                for more information regarding uploading a completed CSV to the Staffing Portal.
              </em>{' '}
            </li>
          </ol>
          <h3 id="finishingUp">Finishing up</h3>
          <p>
            Once you finish entering information and editing, don&#39;t forget these last steps.{' '}
          </p>
          <ol>
            <li>
              When all information is complete and accurate, select <strong>Submit</strong> to
              submit the form.{' '}
            </li>
            <li>
              Verification window confirms submit, select <strong>OK</strong> to confirm submission.
            </li>
            <li>
              Confirmation message{' '}
              <strong>Start contracts request was successfully submitted for approval</strong>{' '}
              appears when successful.{' '}
            </li>
          </ol>
          <h3>View and Manage Contingent Workers</h3>
          <h4>View/manage candidate information</h4>
          <p>
            Select the <strong>View/Manage Contingent Workers</strong> tab to view worker
            information. Use the dropdown to choose a contract group.{' '}
          </p>
          <p>
            On this page you can view workers pending approval and workers who are actively working.
            All information that has been entered is displayed here.{' '}
            <em>
              Refer to <a href="/dashboard">All about Staffing Portal</a> for more information about
              adding and editing worker information.
            </em>
          </p>
          <h4>Terminate a worker</h4>
          <p>To request that a worker be terminated:</p>
          <ol>
            <li>
              Select the <strong>Terminate</strong> button beside the name of the employee.{' '}
              <em>A confirmation window displays.</em>
            </li>
            <li>
              Select <strong>OK</strong> to terminate the worker or <strong>Cancel</strong> if you
              do not wish to continue with termination.{' '}
            </li>
          </ol>
        <div className="legal-disclaimer">
          RED VENTURES PROPRIETARY & CONFIDENTIAL INFORMATION – © 2019 RED VENTURES, LLC. ALL RIGHTS
          RESERVED.
        </div>
      </StyledDashboard>

    );
  }
}

export default Dashboard;
