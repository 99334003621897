import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { LogIn } from "react-feather";
import Button from "../Button";
import { StyledParagraph, StyledHeader } from "../../styles/components";
import ButtonLoader from "../ButtonLoader";
import { fadeOut, slideOut } from "../../styles/keyframes";
import { useAuth0 } from "../../util/auth";
import { lightFormat } from "date-fns";

const StyledLoginScreen = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${props => props.theme.colors.primaryGradient};
  }
  .logo {
    font-family: ${props => props.theme.fonts.bodyFamily};
    font-weight: ${props => props.theme.fonts.bodyWeight};
    font-size: 1.75rem;
    width: auto;
    margin: 15px auto 0 1.5rem;
    color: ${props => props.theme.colors.white};
  }

  .current-time {
    position: absolute;
    bottom: 15px;
    left: 1.5rem;
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.bodyFamily};
    font-weight: ${props => props.theme.fonts.bodyWeight};
    font-size: 7rem;
    letter-spacing: 1px;
  }

  .panel,
  .content {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
  }

  .panel {
    width: 50vw;
    background-color: ${props => props.theme.colors.white};
    overflow: hidden;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }

  &.animating .overlay {
    animation: ${fadeOut} ease-in-out 0.5s forwards normal;
  }

  &.animating .panel {
    animation: ${slideOut} ease-in-out 0.35s 0.15s forwards normal;
  }
`;

const Title = styled.h1`
  font-size: 6rem;
  color: ${props => props.theme.colors.primary};
  font-weight: 700;
  margin-bottom: 0;
`;

const Description = styled.p`
  font-size: 2rem;
  margin: 1rem 0 2.5rem;
  color: ${props => props.theme.colors.primary};
`;

function LoginScreen({ animation }) {
  const { loading, login } = useAuth0();
  let [currentTime, setCurrentTime] = useState(
    lightFormat(new Date(), "h:mm aaa")
  );

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentTime(lightFormat(new Date(), "h:mm aaa"));
    }, 1000);
    return () => clearInterval(interval);
  }, [currentTime]);

  return (
    <StyledLoginScreen className={`okta-stykled ${animation}`}>
      <div className="overlay">
        <StyledHeader className="logo" as="h1">
          Staffing Portal
        </StyledHeader>
        <span className="current-time">{currentTime}</span>
      </div>
      <div className="panel">
        <div className="content">
          <div className="inner">
            <StyledParagraph
              as={Description}
              style={{ margin: 0, fontSize: "1.5rem" }}
            >
              Welcome to
            </StyledParagraph>
            <StyledHeader as={Title}>Staffing Portal</StyledHeader>
            <StyledParagraph as={Description}>
              An application to manage contingent employees.
            </StyledParagraph>
            <Button
              variant="gradient"
              size="hg"
              display="inline-flex"
              className="icon-right"
              onClick={login}
            >
              <Fragment>
                {loading ? (
                  <ButtonLoader text="Authenticating" />
                ) : (
                  <Fragment>
                    {" "}
                    Authenticate with Okta <LogIn />
                  </Fragment>
                )}
              </Fragment>
            </Button>
          </div>
        </div>
      </div>
    </StyledLoginScreen>
  );
}

export default LoginScreen;
