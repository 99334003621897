import config from "../util/config";
import jsCookie from "js-cookie";

function request(route, method, data = null) {
  console.log(`Base URL: ${config.CONTRACT_API_URL}`);
  const url = `${config.CONTRACT_API_URL}${route}`;

  const jwt =
    localStorage.getItem(config.AUTH_STORAGE_KEY) || jsCookie.get(config.AUTH_STORAGE_KEY);

  if (!jwt) {
    throw Error("No token found");
  }

  const params = {
    method,
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  if (["POST", "PATCH", "PUT", "DELETE"].includes(method) && data) {
    params.body = JSON.stringify(data);
  }

  return fetch(url, params);
}

export function checkUniqueStatus(ids) {
  const req = {
    uniqueIDs: ids,
  };
  return request(`/employees/accounts/ids`, "POST", req);
}

export function getSuppliers() {
  return request("/supplier", "GET");
}

export function getFields(supplierId) {
  return request(`/supplier/${supplierId}/form`, "GET");
}

export function startContracts(supplierId, profileId, phoneSystemId, locationId, workers) {
  const req = {
    profileId,
    phoneSystemId,
    locationId,
    workers,
  };

  return request(`/supplier/${supplierId}/form`, "POST", req);
}

export function getPendingWorkers(supplierId) {
  return request(`/supplier/${supplierId}/pending`, "GET");
}

export function getWorkers(supplierId) {
  return request(`/supplier/${supplierId}/employee`, "GET");
}

export function recreateWorker(contingentWorkerID, candidateID, supplierID) {
  const req = {
    contingent_worker_id: parseInt(contingentWorkerID),
    candidate_id: candidateID,
    supplier_id: supplierID,
  };

  return request(`/employees/accounts/new`, "POST", req);
}

export function terminateWorker(supplierId, employeeId, lastDayWorked) {
  const req = {
    last_day_worked: lastDayWorked,
  };

  return request(`/supplier/${supplierId}/employee/${employeeId}`, "DELETE", req);
}
