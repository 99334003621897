import { Button as BootstrapButton } from "react-bootstrap";
import styled from "styled-components";
import { LightenDarkenColor, PropStyles } from "../styles/helpers";

/*
 ** Variations of Buttons Based on props.variant
 */
const ButtonVariants = PropStyles("variant", ({ colors }) => {
  return {
    primary: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      "&:hover, &:focus, &:active": {
        color: colors.white,
        backgroundColor: LightenDarkenColor(colors.primary, 20)
      }
    },
    secondary: {
      color: colors.white,
      backgroundColor: colors.primaryShades[1],
      borderColor: colors.primaryShades[1],
      "&:hover, &:focus, &:active": {
        color: colors.white,
        backgroundColor: LightenDarkenColor(colors.primaryShades[1], 20)
      }
    },
    tertiary: {
      color: colors.white,
      backgroundColor: colors.primaryShades[3],
      borderColor: colors.primaryShades[3],
      "&:hover, &:focus, &:active": {
        color: colors.white,
        backgroundColor: LightenDarkenColor(colors.primaryShades[3], 50)
      }
    },
    success: {
      color: colors.white,
      backgroundColor: colors.successColor,
      borderColor: colors.successColor,
      "&:hover, &:focus, &:active": {
        color: colors.white,
        backgroundColor: LightenDarkenColor(colors.successColor, 20)
      }
    },
    danger: {
      color: colors.white,
      backgroundColor: colors.dangerColor,
      borderColor: colors.dangerColor,
      "&:hover, &:focus, &:active": {
        color: colors.white,
        backgroundColor: LightenDarkenColor(colors.dangerColor, 20)
      }
    },
    link: {
      color: colors.primaryShades[2],
      backgroundColor: "transparent",
      border: "none",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      "&:hover, &:focus, &:active": {
        color: colors.primaryShades[1],
        svg: {
          color: colors.primaryShades[1]
        }
      }
    },
    muted: {
      color: colors.textColor,
      backgroundColor: colors.lightGrey,
      borderColor: colors.mediumGrey,
      "&:hover, &:focus, &:active": {
        color: colors.textColor,
        backgroundColor: LightenDarkenColor(colors.mediumGrey, 50)
      },
      "&:disabled": {
        opacity: 1,
        color: LightenDarkenColor(colors.textColor, 65),
        cursor: "not-allowed"
      },
      "&:hover:disabled": {
        opacity: 1,
        color: LightenDarkenColor(colors.textColor, 65),
        backgroundColor: colors.lightGrey
      }
    },
    ghost: {
      color: colors.primaryShades[2],
      backgroundColor: "transparent",
      borderColor: colors.primaryShades[2],
      "&:hover, &:focus, &:active": {
        color: colors.primaryShades[1],
        borderColor: colors.primaryShades[1],
        backgroundColor: colors.whiteGrey,
        svg: {
          color: colors.primaryShades[1]
        }
      }
    },
    ghostDanger: {
      color: colors.dangerColor,
      backgroundColor: "transparent",
      border: "1px solid transparent",
      "&:hover, &:focus, &:active": {
        color: LightenDarkenColor(colors.dangerColor, 25),
        border: "1px solid transparent",
        backgroundColor: "transparent",
        svg: {
          color: LightenDarkenColor(colors.dangerColor, 25)
        }
      }
    },
    gradient: {
      color: colors.white,
      transition: "all .2s ease",
      backgroundSize: "200% auto",
      backgroundImage: `linear-gradient(
        150deg,
        ${colors.primary} 15%,
        ${colors.primaryShades[1]} 94%
      )`,
      borderColor: colors.primary,
      "&:hover, &:focus, &:active": {
        color: colors.white,
        backgroundPosition: "right center"
      }
    },
    arvy: {
      color: colors.white,
      backgroundColor: colors.redventures,
      borderColor: colors.redventures,
      "&:hover, &:focus, &:active": {
        color: colors.white,
        backgroundColor: LightenDarkenColor(colors.redventures, 20)
      }
    }
  };
});

/*
 ** Variations of Buttons Based on props.variant
 */
const ButtonSizes = PropStyles("size", () => {
  return {
    mn: {
      fontSize: ".5rem",
      svg: {
        height: "0.65rem"
      }
    },
    sm: {
      fontSize: ".75rem",
      svg: {
        height: "0.85rem"
      }
    },
    md: {
      fontSize: "1rem",
      svg: {
        height: "0.85rem"
      }
    },
    lg: {
      fontSize: "1.25rem",
      svg: {
        height: "0.85rem"
      }
    },
    hg: {
      fontSize: "1.65rem",
      svg: {
        height: "1.5rem"
      }
    }
  };
});

// Button Overrides
const Button = styled(BootstrapButton).attrs(props => ({
  variant: props.variant || "primary",
  size: props.size || "md",
  display: props.display || "flex"
}))`
  && {
    border-radius: 0.35rem;
    padding: 0.35rem 1.5rem;
    text-decoration: none;
    font-family: ${props => props.theme.fonts.headerFamily};
    font-weight: ${props => props.theme.fonts.headerWeight};
    border: 1px solid;
    display: ${props => props.display};
    align-items: center;
    transition: all .15s ease-in-out;

    /* Icon */
    svg {
      transition: all .15s ease-in-out;
      color: inherit;
      width: auto;
      margin: 0 0.75rem 0 0;
    }
    &.icon-right svg {
      margin: 0 0 0 0.75rem;
    }

    ${ButtonVariants}
    ${ButtonSizes}

    /* &:hover svg,
    &:active svg,
    &:focus svg {
      color: ${props => props.theme.colors.white};
    } */
  }
`;

export default Button;
