import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Toast as BootstrapToast } from "react-bootstrap";
import { PropStyles } from "../../styles/helpers";
import { StyledHeader } from "../../styles/components";
import { CheckCircle, XCircle } from "react-feather";
import { toastDuration } from "../../styles/keyframes";
import { useToasts } from "../../util/toasts";

const StyledToastsWrapper = styled.div`
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
`;

/*
 ** Variations of Toasts based on their type
 */
const ToastTypes = PropStyles("type", ({ colors }) => {
  return {
    default: {
      ".toast-header": {
        color: colors.primary
      }
    },
    success: {
      ".toast-header": {
        color: colors.successColor
      }
    },
    error: {
      ".toast-header": {
        color: colors.dangerColor
      }
    }
  };
});

const StyledToast = styled(BootstrapToast).attrs(props => ({
  toastcolor:
    props.type === "success"
      ? props.theme.colors.successColor
      : props.type === "error"
      ? props.theme.colors.dangerColor
      : props.theme.colors.primary
}))`
  && {
    min-width: 240px;
    .toast-header {
      display: flex;
      justify-content: space-between;

      svg {
        height: 0.8rem;
        width: auto;
        margin-right: 0.5rem;
      }
    }
    .toast-body {
      padding: 0.75rem 0.75rem 1rem 0.75rem;
      color: ${props => props.theme.colors.textColor};
      font-family: ${props => props.theme.fonts.bodyFamily};
      font-weight: ${props => props.theme.fonts.bodyWeight};
    }

    /* If Expires */
    ${props =>
      props.autohide &&
      css`
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 0.25rem;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: ${props => props.toastcolor};
          animation: ${toastDuration} 3s forwards linear;
        }
      `}

    ${ToastTypes}
  }
`;

function ToastsWrapper() {
  let { toasts } = useToasts();

  // For Performance
  return useMemo(
    () => (
      <StyledToastsWrapper>
        {toasts.map((toast, i) => (
          <Toast key={toast.id} {...toast} />
        ))}
      </StyledToastsWrapper>
    ),
    [toasts]
  );
}

function Toast({ id, title, body, expires, type }) {
  const { dismissToast } = useToasts();

  let [show, setShow] = useState(true);

  function handleClose(id) {
    setShow(false);
    setTimeout(() => dismissToast(id), 150);
  }

  return (
    <StyledToast
      onClose={() => handleClose(id)}
      show={show}
      delay={expires ? 3000 : null}
      autohide={expires}
      type={type}
    >
      <BootstrapToast.Header>
        <StyledHeader>
          {type === "success" && <CheckCircle />}
          {type === "error" && <XCircle />}
          {title}
        </StyledHeader>
      </BootstrapToast.Header>
      {body && <BootstrapToast.Body>{body}</BootstrapToast.Body>}
    </StyledToast>
  );
}

Toast.defaultProps = {
  autohide: false,
  title: "Nice Toast!",
  body: "This is the body content of a very nice looking toast.",
  type: "default"
};

export default ToastsWrapper;
