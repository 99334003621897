import React from "react";
import styled from "styled-components";
import { loaderSpin } from "../styles/keyframes";

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  .loader {
    margin: 0 0 0 1.5rem;
    position: relative;
    text-indent: -9999em;
    border-top: 0.1em solid rgba(255, 255, 255, 0.2);
    border-right: 0.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.1em solid rgba(255, 255, 255, 0.2);
    border-left: 0.1em solid #ffffff;
    transform: translateZ(0);
    animation: ${loaderSpin} 1.1s infinite linear;

    &,
    &:after {
      border-radius: 50%;
      width: 1em;
      height: 1em;
    }
  }
`;

const ButtonLoader = props => (
  <StyledLoader className="button-loader" {...props}>
    {props.text && <span className="text">{props.text}</span>}
    <div className="loader"/>
  </StyledLoader>
);

export default ButtonLoader;
