import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ChevronRight, ChevronLeft } from "react-feather";
// import { routes } from "../../util/router";
import { navigationLinks as routes } from "../../util/constants";

const StyledSidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: ${props => props.theme.colors.white};
  padding-top: ${props => props.theme.navbarHeight};
  box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;

  nav {
    transition: width 0.25s ease-in-out;
    width: ${props =>
  props.collapsed
    ? props.theme.sidebarCollapsedWidth
    : props.theme.sidebarWidth};
  }

  a,
  .collapse-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    color: ${props => props.theme.colors.textColor};
    transition: all 0.2s ease-in;

    .icon {
      position: relative;
      height: 60px;
      min-width: 60px;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      flex: 1;
      margin-left: 0.5rem;
      font-family: ${props => props.theme.fonts.headerFamily};
      font-weight: ${props => props.theme.fonts.headerWeight};
    }
  }

  .link {
    border-left: 8px solid ${props => props.theme.colors.white};
    margin: 0.5rem 0;
    &:hover {
      color: ${props => props.theme.colors.primaryShades[1]};
    }

    &:hover,
    &.active {
      text-decoration: none;
      border-left: 8px solid ${props => props.theme.colors.primaryShades[3]};
    }

    &.active {
      background-color: ${props => props.theme.colors.primaryShades[1]};
      color: ${props => props.theme.colors.white};
    }
  }

  .collapse-toggle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    justify-content: flex-end;
    &:hover {
      cursor: pointer;
    }
  }
`;

function Sidebar(props) {
  const { collapsed, toggleCollapse } = props;
  return (
    <StyledSidebar collapsed={collapsed}>
      <nav>
        {routes.map(route => (
          <NavLink className="link" key={route.title} to={route.path}>
            <span className="icon">
              <route.icon />
            </span>
            <span className="text">{route.title}</span>
          </NavLink>
        ))}
      </nav>
      <span className="collapse-toggle" onClick={() => toggleCollapse()}>
        <span className="icon">
          {collapsed ? <ChevronRight /> : <ChevronLeft />}
        </span>
      </span>
    </StyledSidebar>
  );
}

export default withRouter(Sidebar);
