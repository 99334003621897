import { Container } from "unstated";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getSuppliers,
  getFields,
  startContracts,
  getPendingWorkers,
  getWorkers,
  recreateWorker,
  terminateWorker,
  checkUniqueStatus,
} from "../client/contractClient";

export default class ContractContainer extends Container {
  state = {
    suppliers: [], // suppliers accessible to logged in user
    fetchingSuppliers: false,
    startContract: {
      ui: {
        supplierId: null,
        worker: null,
      },
      supplierId: null,
      showConfirmSupplier: false,
      fields: [],
      fetchingFields: false,
      workers: [],
      pendingWorkers: [],
      selectedWorkerIndex: null,
      showMissingValues: false,
      showWorkerAddForm: false,
      showWorkerMissingValues: false,
      showTableMissingValues: false,
      submitting: false,
      showConfirmSubmit: false,
      validateUniqueIDs: {
        fetchingStatuses: false,
        duplicateIDs: [],
      },
    },
    manageContracts: {
      supplierId: null,
      pendingWorkers: [],
      workers: [],
      fetchingWorkers: false,
      confirmRecreateCandidateID: null,
      confirmTerminateEEID: null,
      terminating: false,
      recreating: false,
      filter: "",
      date: null,
      lastDayWorked: null,
      focused: false,
      sort: {
        column: null,
        direction: null,
      },
      multiSelect: {
        lastBoxClicked: null,
        lastTypeClicked: null,
      },
    },
  };

  fetchSuppliers() {
    this.setState({ fetchingSuppliers: true });

    const catchErr = (err) => {
      this.setState({
        fetchingSuppliers: false,
      });

      toast.error(`Error loading suppliers: ${err.message}`);
    };

    try {
      getSuppliers()
        .then((response) => {
          if (!response.ok) {
            throw Error(response.status);
          }
          return response.json();
        })
        .then((suppliers) => {
          this.setState({
            suppliers,
            fetchingSuppliers: false,
          });
        })
        .catch((err) => catchErr(err));
    } catch (err) {
      catchErr(err);
    }
  }

  showConfirmSupplier(supplierId) {
    this.setState({
      startContract: {
        ...this.state.startContract,
        showConfirmSupplier: true,
        ui: {
          ...this.state.startContract.ui,
          supplierId,
        },
      },
    });
  }

  declineConfirmSupplier() {
    this.setState({
      startContract: {
        ...this.state.startContract,
        showConfirmSupplier: false,
        ui: {
          ...this.state.startContract.ui,
          supplierId: this.state.startContract.supplierId,
        },
      },
    });
  }

  setStartContractSupplierId(supplierId) {
    this.setState(
      {
        startContract: {
          ...this.state.startContract,
          locationId: 0,
          profileId: 0,
          phoneSystemId: 0,
          supplierId,
          showConfirmSupplier: false,
          ui: {
            supplierId,
          },
        },
      },
      () => {
        this.fetchStartContractFields();
        this.fetchStartContractPendingWorkers();
      }
    );
  }

  setManageContractsSupplierId(supplierId) {
    this.setState(
      {
        manageContracts: {
          ...this.state.manageContracts,
          supplierId,
          fetchingWorkers: true,
        },
      },
      () => {
        const handleErr = (err) => {
          toast.error(`Error loading workers: ${err.message}`);
        };

        try {
          Promise.all([
            getPendingWorkers(supplierId),
            getWorkers(supplierId),
            getFields(supplierId),
          ])
            .then((response) => {
              const getPendingWorkersResponse = response[0];
              const getWorkersResponse = response[1];
              const getFieldsResponse = response[2];
              if (!getPendingWorkersResponse.ok) {
                throw Error(getPendingWorkersResponse.status);
              }
              if (!getWorkersResponse.ok) {
                throw Error(getWorkersResponse.status);
              }
              if (!getFieldsResponse.ok) {
                throw Error(getFieldsResponse.status);
              }
              return Promise.all([
                getPendingWorkersResponse.json(),
                getWorkersResponse.json(),
                getFieldsResponse.json(),
              ]);
            })
            .then((response) => {
              let pendingWorkers = response[0];
              let workers = response[1];
              const fields = response[2];

              if (Array.isArray(workers)) {
                workers = workers.map((worker, i) => {
                  return {
                    ...worker,
                    Index: i,
                    Selected: false,
                  };
                });
              }

              if (Array.isArray(pendingWorkers)) {
                pendingWorkers = pendingWorkers.map((worker, i) => {
                  return {
                    ...worker,
                    Index: i,
                    Selected: false,
                  };
                });
              }

              this.setState({
                manageContracts: {
                  ...this.state.manageContracts,
                  pendingWorkers: pendingWorkers || [],
                  workers: workers || [],
                  fields,
                  filter: "",
                  date: null,
                  lastDayWorked: null,
                  focused: false,
                  sort: {
                    column: null,
                    direction: null,
                  },
                  fetchingWorkers: false,
                  multiSelect: {
                    lastBoxClicked: null,
                    lastTypeClicked: null,
                  },
                },
              });
            })
            .catch((err) => handleErr(err));
        } catch (err) {
          handleErr(err);
        }
      }
    );
  }

  fetchStartContractFields() {
    this.setState({
      startContract: {
        ...this.state.startContract,
        fields: [],
        showMissingValues: false,
        showTableMissingValues: false,
        fetchingFields: true,
        submitting: false,
      },
    });

    const handleErr = (err) => {
      toast.error(`Error loading new contingent workers form: ${err.message}`);
    };

    try {
      getFields(this.state.startContract.supplierId)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.status);
          }
          return response.json();
        })
        .then((fields) => {
          if (fields) {
            fields = fields.map((f) => {
              return {
                ...f,
                value: f.default,
              };
            });
          }

          this.setState({
            startContract: {
              ...this.state.startContract,
              fields: fields || [],
              fetchingFields: false,
            },
            fetchingSuppliers: false,
          });
        })
        .catch((err) => handleErr(err));
    } catch (err) {
      handleErr(err);
    }
  }

  fetchStartContractPendingWorkers() {
    const handleErr = (err) => {
      toast.error(`Error loading pending workers: ${err.message}`);
    };

    const { supplierId } = this.state.startContract;

    try {
      getPendingWorkers(supplierId)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.status);
          }
          return response.json();
        })
        .then((pendingWorkers) => {
          this.setState({
            startContract: {
              ...this.state.startContract,
              pendingWorkers: pendingWorkers || [],
            },
          });
        })
        .catch((err) => handleErr(err));
    } catch (err) {
      handleErr(err);
    }
  }

  setFieldValue(fieldKey, value) {
    const fieldIndex = this.state.startContract.fields.findIndex((field) => field.key === fieldKey);
    const fields = this.state.startContract.fields.slice();
    fields[fieldIndex] = {
      ...fields[fieldIndex],
      value,
    };

    this.setState({
      startContract: {
        ...this.state.startContract,
        fields,
      },
    });
  }

  setStartContractWorkers(workers) {
    this.setState({
      startContract: {
        ...this.state.startContract,
        workers,
      },
    });
  }

  removeStagedWorker(uniqueId) {
    const workers = this.state.startContract.workers.slice();
    const workerIndex = workers.findIndex((w) => w.uniqueId === uniqueId);
    workers.splice(workerIndex, 1);

    const dups = this.state.startContract.validateUniqueIDs.duplicateIDs;
    if (dups.includes(uniqueId)) {
      const dupIndex = dups.findIndex((w) => w === uniqueId);
      dups.splice(dupIndex, 1);
    }

    this.setState({
      startContract: {
        ...this.state.startContract,
        workers,
        validateUniqueIDs: {
          duplicateIDs: dups,
        },
      },
    });
  }

  openWorkerAddForm() {
    this.setState({
      startContract: {
        ...this.state.startContract,
        showWorkerAddForm: true,
        showWorkerMissingValues: false,
        ui: {
          ...this.state.startContract.ui,
          worker: {
            uniqueId: null,
            firstName: null,
            lastName: null,
            workEmailAddress: null,
          },
        },
      },
    });
  }

  openWorkerEditForm(editWorkerId) {
    const { workers } = this.state.startContract;
    const workerIndex = workers.findIndex((w) => w.uniqueId === editWorkerId);

    this.setState({
      startContract: {
        ...this.state.startContract,
        selectedWorkerIndex: workerIndex,
        showWorkerMissingValues: false,
        ui: {
          ...this.state.startContract.ui,
          worker: JSON.parse(JSON.stringify(workers[workerIndex])),
        },
      },
    });
  }

  setWorkerFieldValue(fieldKey, value) {
    const worker = this.state.startContract.ui.worker;
    worker[fieldKey] = value;

    this.setState({
      startContract: {
        ...this.state.startContract,
        ui: {
          ...this.state.startContract.ui,
          worker,
        },
      },
    });
  }

  saveWorker() {
    const { startContract } = this.state;
    const worker = startContract.ui.worker;

    for (var key in worker) {
      if (worker.hasOwnProperty(key)) {
        // trim every field of worker to eliminate surrounding whitespace
        if (worker[key]) worker[key] = worker[key].trim();

        const field = startContract.fields.find((f) => f.key === key); // eslint-disable-line no-loop-func

        // return early and display an error if a required field is empty
        if (field.isRequired && !worker[key]) {
          this.setState({
            startContract: {
              ...startContract,
              showWorkerMissingValues: true,
            },
          });
          return;
        }
      }
    }

    const workers = startContract.workers.slice();

    if (startContract.selectedWorkerIndex !== null) {
      workers[startContract.selectedWorkerIndex] = worker;
    } else {
      workers.push(worker);
    }

    this.checkUniqueIDs(workers);

    this.setState({
      startContract: {
        ...startContract,
        workers,
        showWorkerAddForm: false,
        selectedWorkerIndex: null,
        showWorkerMissingValues: false,
        ui: {
          ...startContract.ui,
          worker: null,
        },
      },
    });
  }

  closeWorkerEditForm() {
    this.setState({
      startContract: {
        ...this.state.startContract,
        selectedWorkerIndex: null,
        showWorkerAddForm: false,
        showWorkerMissingValues: false,
        ui: {
          ...this.state.startContract.ui,
          worker: null,
        },
      },
    });
  }

  validateWorkers() {
    const { startContract } = this.state;

    let valueMissing = false;
    let duplicateUniqueIds = false;
    const uniqueIds = [];
    const duplicateIds = new Set();
    const uniqueIdFieldExists = startContract.fields.some((f) => f.key === "uniqueId");

    startContract.workers.forEach((worker) => {
      startContract.fields.forEach((field) => {
        if (field.isRequired && !worker[field.key].trim()) {
          valueMissing = true;
        }
      });

      if (uniqueIdFieldExists) {
        if (uniqueIds.includes(worker["uniqueId"])) {
          duplicateIds.add(worker["uniqueId"]);
          duplicateUniqueIds = true;
        }

        uniqueIds.push(worker["uniqueId"]);
      }
    });

    if (valueMissing) {
      this.setState({
        startContract: {
          ...startContract,
          showMissingValues: true,
        },
      });
      return false;
    }

    if (duplicateUniqueIds) {
      const errStr = "Validation error: The following unique ids are not unique: " + [...duplicateIds].join(', ');
      toast.error(errStr);
      return false;
    }

    return true;
  }

  showConfirmSubmit() {
    this.setState({
      startContract: {
        ...this.state.startContract,
        showConfirmSubmit: true,
      },
    });
  }

  cancelConfirmSubmit() {
    this.setState({
      startContract: {
        ...this.state.startContract,
        showConfirmSubmit: false,
      },
    });
  }

  submitStartContracts() {
    this.setState(
      {
        startContract: {
          ...this.state.startContract,
          showConfirmSubmit: false,
          submitting: true,
        },
      },
      () => {
        const { startContract } = this.state;

        const handleErr = (err) => {
          this.setState({
            startContract: {
              ...startContract,
              submitting: false,
            },
          });

          toast.error(`Error submitting start contracts request: ${err.message}`);
        };

        try {
          startContracts(
            startContract.supplierId,
            startContract.profileId,
            startContract.phoneSystemId,
            startContract.locationId,
            startContract.workers
          )
            .then((response) => {
              return Promise.all([response.status, response.json()]);
            })
            .then((response) => {
              const statusCode = response[0];
              const responseData = response[1];
              if (responseData.error) {
                let errorMessage = "";
                let workerIdsWithErrors;

                if (responseData.details) {
                  const errorDetails = responseData.details;
                  let errorAdded = false;
                  workerIdsWithErrors = [];

                  for (var key in errorDetails) {
                    if (errorDetails.hasOwnProperty(key)) {
                      const uniqueId = key;
                      workerIdsWithErrors.push(uniqueId);

                      if (errorAdded) {
                        errorMessage += ". ";
                      }

                      let message = errorDetails[uniqueId].message;
                      if (message.includes("Duplicate insert on index: Applicant-ID")) {
                        message = "The entered unique id already exists for this supplier";
                      }

                      errorMessage += `For worker ${uniqueId}: ${message}`;

                      if (errorDetails[uniqueId].fields) {
                        const fields = errorDetails[uniqueId].fields;
                        let fieldErrorAdded = false;

                        for (var fieldKey in fields) {
                          if (fields.hasOwnProperty(fieldKey)) {
                            if (!fieldErrorAdded) {
                              errorMessage += " - see field(s) ";
                            } else {
                              errorMessage += ", ";
                            }

                            errorMessage += `${fieldKey}`;
                            fieldErrorAdded = true;
                          }
                        }
                      }

                      errorAdded = true;
                    }
                  }
                }

                if (responseData.error === "complete error") {
                  errorMessage = `Error submitting start contracts request: ${errorMessage}`;

                  this.setState({
                    startContract: {
                      ...startContract,
                      submitting: false,
                    },
                  });
                } else if (responseData.error === "partial error") {
                  const workers = startContract.workers.filter((w) =>
                    workerIdsWithErrors.includes(w.uniqueId)
                  );

                  let successMessage = "";
                  if (workers.length < startContract.workers.length) {
                    successMessage =
                      "Some workers were successfully submitted and have been removed from the data to submit. ";
                  }

                  errorMessage = `Error submitting start contracts request. ${successMessage}Error(s): ${errorMessage}`;

                  this.setState({
                    startContract: {
                      ...startContract,
                      submitting: false,
                      workers,
                    },
                  });
                }

                if (errorMessage) {
                  toast.error(errorMessage);
                }

                this.fetchStartContractPendingWorkers();
              } else if (statusCode === 200) {
                toast.success(`Start contracts request was successfully submitted for approval`);

                this.setState({
                  startContract: {
                    ...startContract,
                    submitting: false,
                    workers: [],
                    showMissingValues: false,
                  },
                });

                this.fetchStartContractPendingWorkers();
              } else {
                throw Error(`Error code - ${statusCode}`);
              }
            })
            .catch((err) => handleErr(err));
        } catch (err) {
          handleErr(err);
        }
      }
    );
  }

  confirmRecreateWorker(confirmRecreateCandidateID) {
    this.setState({
      manageContracts: {
        ...this.state.manageContracts,
        confirmRecreateCandidateID,
      },
    });
  }

  cancelRecreateWorker() {
    this.setState({
      manageContracts: {
        ...this.state.manageContracts,
        confirmRecreateCandidateID: null,
      },
    });
  }

  confirmTerminateWorker(confirmTerminateEEID) {
    this.setState({
      manageContracts: {
        ...this.state.manageContracts,
        confirmTerminateEEID,
      },
    });
  }

  cancelTerminateWorker() {
    this.setState({
      manageContracts: {
        ...this.state.manageContracts,
        confirmTerminateEEID: null,
        lastDayWorked: null,
      },
    });
  }

  recreateWorker() {
    const recreateCandidateID = this.state.manageContracts.confirmRecreateCandidateID;

    this.setState({
      manageContracts: {
        ...this.state.manageContracts,
        recreating: true,
      },
    });

    const handleErr = (err) => {
      this.setState({
        manageContracts: {
          ...this.state.manageContracts,
          recreating: false,
        },
      });

      toast.error(`Error recreating worker accounts: ${err.message}`);
    };

    let workers = this.state.manageContracts.workers.slice();
    const workerIndex = workers.findIndex((w) => w.PreHireID === recreateCandidateID);

    try {
      recreateWorker(
        workers[workerIndex].EmployeeID,
        workers[workerIndex].PreHireID,
        this.state.manageContracts.supplierId
      )
        .then((response) => {
          if (!response.ok) {
            throw Error(response.status);
          }
        })
        .then(() => {
          toast.success(
            `${workers[workerIndex].LegalName.First} ${workers[workerIndex].LegalName.Last} successfully received new accounts`
          );

          this.setState({
            manageContracts: {
              ...this.state.manageContracts,
              recreating: false,
              confirmRecreateCandidateID: null,
            },
          });
        })
        .catch((err) => handleErr(err));
    } catch (err) {
      handleErr(err);
    }
  }

  terminateWorker() {
    const terminateEEID = this.state.manageContracts.confirmTerminateEEID;

    this.setState({
      manageContracts: {
        ...this.state.manageContracts,
        terminating: true,
      },
    });

    const handleErr = (err) => {
      this.setState({
        manageContracts: {
          ...this.state.manageContracts,
          terminating: false,
        },
      });

      toast.error(`Error terminating worker: ${err.message}`);
    };

    if (this.state.manageContracts.lastDayWorked === null) {
      handleErr(new Error("You must provide a valid termination date"));
      return;
    }

    const worker = this.state.manageContracts.workers.find(w => w.EmployeeID === terminateEEID)

    const lastDayWorked = moment(this.state.manageContracts.lastDayWorked).format("YYYY-MM-DDTHH:mm:ssZ");

    if (lastDayWorked < worker.HireDate) {
      handleErr(new Error(`Termination date should be after the Hire date: ${worker.HireDate}`));
      return;
    }

    try {
      terminateWorker(this.state.manageContracts.supplierId, terminateEEID, lastDayWorked)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.status);
          }
          return response.json();
        })
        .then(() => {
          let workers = this.state.manageContracts.workers.slice();
          const workerIndex = workers.findIndex((w) => w.EmployeeID === terminateEEID);

          toast.success(
            `${workers[workerIndex].LegalName.First} ${workers[workerIndex].LegalName.Last} was successfully terminated`
          );

          workers.splice(workerIndex, 1);

          this.setState({
            manageContracts: {
              ...this.state.manageContracts,
              terminating: false,
              confirmTerminateEEID: null,
              lastDayWorked: null,
              workers,
            },
          });
        })
        .catch((err) => handleErr(err));
    } catch (err) {
      handleErr(err);
    }
  }

  getUniqueId(worker) {
    const separatorIndex = worker.PreHireID ? worker.PreHireID.indexOf("_") : null;

    if (separatorIndex && separatorIndex !== -1 && separatorIndex < worker.PreHireID.length - 1) {
      return worker.PreHireID.substr(
        separatorIndex + 1,
        worker.PreHireID.length - 1 - separatorIndex
      );
    }

    return null;
  }

  checkUniqueIDs(workers) {
    this.setState({
      startContract: {
        ...this.state.startContract,
        validateUniqueIDs: {
          ...this.state.startContract.validateUniqueIDs,
          fetchingStatuses: true,
        }
      }
    });

    let uniqueIDs = [];
    workers.forEach((v, i) => {
      uniqueIDs.push(v.uniqueId);
    });

    const handleErr = (err) => {
      toast.error("Error checking the uniqueness of the unique IDs");
      this.setState({
        startContract: {
          ...this.state.startContract,
          validateUniqueIDs: {
            ...this.state.startContract.validateUniqueIDs,
            fetchingStatuses: false,
          }
        }
      });
      console.log(err);
    }

    const compareIDs = (response) => {
      let stats = response.id_statuses;
      let duplicates = [];

      // check status of each ID
      uniqueIDs.forEach((v, i) => {
        if (stats[v] === false) {
          duplicates.push(v);
        }
      });

      if (duplicates.length > 0) {
        let dupsError = "Error Submitting workers. The following Unique IDs are not Unique: " + duplicates.join(', ');
        toast.error(dupsError);
      }

      this.setState({
        startContract: {
          ...this.state.startContract,
          validateUniqueIDs: {
            duplicateIDs: duplicates,
            fetchingStatuses: false,
          }
        }
      });
    }

    try {
      checkUniqueStatus(uniqueIDs)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.status);
          }

          return response.json().then((parsed) => compareIDs(parsed))
            .catch((err) => handleErr(err));
        })
        .catch((err) => handleErr(err));
    } catch (err) {
      handleErr(err);
    }
  }
}
