import {
    css
}
from '@emotion/core';

export default css ` .required::after {
    content: ' *';
}
.missing-value {
    background-color: rgba(236, 88, 64, 0.1);
}
.field {
    max-width: 30%;
}
.missing-values-alert {
    color: red;
}
.icon {
    cursor: pointer;
}
.field-group {
    margin-bottom: 15px;
}
.field-group p {
    margin-bottom: 5px;
}
.ui.segment {
    margin-bottom: 14px;
}
.table-container {
    margin-bottom: 1em;
    overflow-x: auto;
}
.file-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.file-input-container * {
    margin-right: 2em;
}

#group-fields {
    margin-top: 3em;
    margin-bottom: 3em;
}
#csv-template {
    margin-bottom: 1em;
}
#start-contract-form-report-divider {
    margin-top: 3em;
    margin-bottom: 3em;
}

#input-files-button {
    font-size: .92857143rem cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #DEDFE1 none;
    color: rgba(0, 0, 0, 0.6);
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.54em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;
}
`;
