import React from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import styled from "styled-components";

const StyledDateSelect = styled.div`
  position: relative;
  padding: 0.5rem 0;
  margin-left: 15px;
  margin-right: 15px;
  .field {
    /* this is because when the datepicker is open the margin changes because
    of another class and the css is weird so we can't seem to fix it any other way */
    margin-bottom: 0 !important;
  }
`;

const DateSelect = ({ date, placeholder, onChange, maxDate }) => {
  return (
    <StyledDateSelect>
      <SemanticDatepicker value={date} placeholder={placeholder} onChange={onChange} maxDate={maxDate ? maxDate : null} />
    </StyledDateSelect>
  );
};

export default DateSelect;
